import React, { useEffect, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faLink, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons'
import { MediaContext } from '../../contexts/media'
import Breadcrumbs from '../../components/breadcrumbs'
import Paginator from '../../components/paginator'
import { ModalContext } from '../../contexts/modal'
import { TooltipContext } from '../../contexts/tooltip'
import UploadImages from '../../components/upload-images'
import ConfirmationModal from '../../components/confirmation-modal'
import Utils from '../../utils'
import Spinner from '../../components/spinner'
import Service from '../../service'
import key from './key'
import './index.scss'

const { getAspectRatio, copyStringToClipboard } = new Utils()
const RESULTS_PER_PAGE = 12

const Media = () => {
  const { media, mediaCollections, mediaMethods, mediaIsFetching } = useContext(MediaContext)
  const { getMedia, deleteMedia, lockMedia, unlockMedia, getMediaCollections, deleteMediaCollection } = mediaMethods
  const { modalMethods } = useContext(ModalContext)
  const { tooltip, tooltipMethods } = useContext(TooltipContext)
  const [ page, setPage ] = useState(1)
  const formatName = name => name.length > 15 ? `${name.substr(0, 15)}... ${name.split('.')[1]}` : name
  useEffect(()=>{
    getMedia()
    getMediaCollections()
  },[])
  return (
    <div className="section" id="media">
      <Breadcrumbs />
      <div className="columns is-multiline is-variable">
        <div className="column">
          <div className="box">
            <div className="columns">
              <div className="column">
                <h3 className="m-b-md has-text-primary">Collections</h3>
              </div>
              <div className="column is-narrow">
                <Link to="/media-collection">
                  <button className="button is-primary is-small has-text-weight-bold">
                    New +
                  </button>
                </Link>
              </div>
            </div>
            <table className="table is-bordered is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>
                    Title
                  </th>
                  <th className="is-narrow">
                    Assigned To
                  </th>
                  <th className="is-narrow is-hidden-mobile">
                    Created
                  </th>
                  <th className="is-narrow is-hidden-mobile">
                    Updated
                  </th>
                  <th className="is-narrow" />
                </tr>
              </thead>
              <tbody>
                {mediaCollections.map((collection)=>(
                  <tr key={collection.id}>
                    <td>
                      <Link to={`/media-collection?id=${collection.id}`}>{collection.title}</Link>
                    </td>
                    <td>{collection.assigned_to.name}</td>
                    <td className="no-wrap is-hidden-mobile">
                      {moment(collection.created_at).format('M-D-YYYY h:mmA')}
                    </td>
                    <td className="no-wrap is-hidden-mobile">
                      {moment(collection.updated_at).format('M-D-YYYY h:mmA')}
                    </td>
                    <td>
                      <button
                        className="button is-danger is-small has-text-weight-bold"
                        onClick={()=>{
                          modalMethods.setContent(()=>(
                            <ConfirmationModal
                              submit={()=>{
                                deleteMediaCollection(collection.id)
                                modalMethods.close()
                              }}
                              cancel={()=>{
                                modalMethods.close()
                              }}
                            />
                          ))
                          modalMethods.open('300px')
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <UploadImages />
      <div className="box">
        <h3 className="m-b-md has-text-primary">All Images</h3>
        {(mediaIsFetching)?(
          <div className="columns is-vcentered is-fullheight">
            <div className="column has-text-centered">
              <Spinner size="3x" />
            </div>
          </div>
        ):(
          <div className="columns is-multiline is-variable">
            {media.length > 0 ? media.map((image, i)=>{
              if ((i+1) > (page * RESULTS_PER_PAGE) || (i+1) < ((page-1) * RESULTS_PER_PAGE)) return null
              const params = `?w=:n`
              return (
                <div className="column is-4-tablet is-3-desktop is-2-fullhd has-text-centered has-text-info" key={`image-${i}`}>
                  <div className={`image ${getAspectRatio(image.width, image.height)}`}>
                    <img src={`${Service.CDN}${image.filePath}?w=300`} />
                    <FontAwesomeIcon
                      icon={image.locked ? faLock : faUnlock}
                      className="lock-icon"
                      onClick={()=>{
                        const action = (image.locked) ? ()=>{
                          modalMethods.setContent(()=>(
                            <ConfirmationModal
                              submit={async ()=>{
                                await unlockMedia(image.name)
                                getMedia()
                                modalMethods.close()
                              }}
                              cancel={()=>{
                                modalMethods.close()
                              }}
                            />
                          ))
                          modalMethods.open('300px')
                        } : async () => {
                          await lockMedia(image.name)
                          getMedia()
                        }
                        action()
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className={`x-icon ${image.locked?'is-hidden':''} `}
                      onClick={()=>{
                        modalMethods.setContent(()=>(
                          <ConfirmationModal
                            submit={()=>{
                              deleteMedia(image.name).then(()=>{
                                getMedia()
                              })
                              modalMethods.close()
                            }}
                            cancel={()=>{
                              modalMethods.close()
                            }}
                          />
                        ))
                        modalMethods.open('300px')
                      }}
                    />
                    <p className="image-info">
                      <FontAwesomeIcon
                        icon={faLink}
                        onMouseEnter={(e)=>{
                          const { top, left, right } = e.target.getBoundingClientRect()
                          const isReverse = e.pageX > (window.innerWidth*0.75)
                          const horizontal = isReverse ? left : right
                          tooltipMethods.setPosition({
                            vertical: top + window.scrollY,
                            horizontal,
                          })
                          tooltipMethods.setContent(()=>(
                            <aside className="menu has-background-white-bis">
                              <ul className="menu-list p-none">
                                {
                                  key.map(({ size, display })=>(
                                    
                                    <li
                                      onClick={()=>{
                                        copyStringToClipboard(Service.CDN+image.filePath+params.replace(/:n/, size))
                                        tooltipMethods.hide()
                                      }}
                                      key={`tooltip-${image.name}-${display}`}
                                    ><a className="p-l-md p-r-md pointer">{display}</a></li>
                                  ))
                                }
                              </ul>
                            </aside>
                          ))
                          setTimeout(()=>{
                            tooltipMethods.show()
                          },0)
                        }}
                      />
                      <br />
                      {formatName(image.name)}
                    </p>
                  </div>
                </div>
              )
            }) : (
              <h3>No media to show.</h3>
            )}
          </div>
        )}
        {media.length > RESULTS_PER_PAGE ? (
          <div className="columns">
            <div className="column has-text-right">
              <Paginator
                count={media.length}
                limit={RESULTS_PER_PAGE}
                page={page}
                action={setPage}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Media
