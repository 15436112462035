import React, { useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { SeoContext } from '../../contexts/seo'
import Breadcrumbs from '../../components/breadcrumbs'

const SeoCreate = () => {
  const { createSeoRoute } = useContext(SeoContext).seoMethods
  const history = useHistory()
  const refs = {
    route: useRef(),
    title: useRef(),
    description: useRef(),
    image: useRef(),
    ogType: useRef(),
  }
  const createRoute = () => {
    const body = {
      route: refs.route.current.value,
      title: refs.title.current.value,
      description: refs.description.current.value,
      image: refs.image.current.value,
      og_type: refs.ogType.current.value,
    }
    let isEmpty = false
    Object.values(body).forEach(value => {
      if (!value) isEmpty = true
    })
    if (isEmpty) return
    createSeoRoute(body).then(()=>{
      history.push('/seo')
    })
  }
  return (
    <div className="section">
      <Breadcrumbs />
      <div className="box">
        <form className="form">
          <div className="field">
            <label className="label" htmlFor="route">Route</label>
            <div className="control">
              <input
                type="text"
                className="input"
                id="route"
                ref={refs.route}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="title">Title</label>
            <div className="control">
              <input
                type="text"
                className="input"
                id="title"
                ref={refs.title}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="description">Description</label>
            <div className="control">
              <input
                type="text"
                className="input"
                id="description"
                ref={refs.description}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="image">Image</label>
            <div className="control">
              <input
                type="text"
                className="input"
                id="image"
                ref={refs.image}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="og_type">Type</label>
            <div className="control">
              <input
                type="text"
                className="input"
                id="og_type"
                ref={refs.ogType}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button
                type="button"
                className="button is-primary"
                onClick={createRoute}
              >Create</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SeoCreate
