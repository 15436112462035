import React, { createContext, useState, useEffect } from 'react'
import Service from '../service'
import moment from 'moment'

export const BlogContext = createContext()

const NEVER = moment(0).add(200, 'years').format('YYYY-MM-DD')

const BlogProvider = props => {
  const [ blog, setBlog ] = useState([])
  const [ blogIsFetching, setBlogIsFetching ] = useState(false)

  const blogMethods = {
    getBlog: async () => {
      setBlogIsFetching(true)
      const res = await Service.getBlogPosts()
      const { blog } = await res.json()
      setBlog(blog)
      setBlogIsFetching(false)
    },
    createBlogPost: async (body) => {
      setBlogIsFetching(true)
      const res = await Service.createBlogPost(body)
      const { blog } = await res.json()
      setBlog(blog)
      setBlogIsFetching(false)
    },
    updateBlogPost: async (id, body) => {
      setBlogIsFetching(true)
      const res = await Service.updateBlogPost(id, body)
      const { blog } = await res.json()
      setBlog(blog)
      setBlogIsFetching(false)
    },
    deleteBlogPost: async (id) => {
      setBlogIsFetching(true)
      const res = await Service.deleteBlogPost(id)
      const { blog } = await res.json()
      setBlog(blog)
      setBlogIsFetching(false)
    },
    lockBlogPost: async (id) => {
      setBlogIsFetching(true)
      await Service.lockBlogPost(id)
      setBlogIsFetching(false)
    },
    unlockBlogPost: async (id) => {
      setBlogIsFetching(true)
      await Service.unlockBlogPost(id)
      setBlogIsFetching(false)
    }
  }

  return (
    <BlogContext.Provider
      value={{blog, blogMethods, blogIsFetching}}
    >
      {props.children}
    </BlogContext.Provider>
  )
}

export default BlogProvider
