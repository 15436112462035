export default [{
  display: 'Thumbnail',
  size: 150,
},{
  display: 'Medium',
  size: 300,
},{
  display: 'Medium Large',
  size: 768,
},{
  display: 'Large',
  size: 768,
},{
  display: 'Original',
  size: 768,
}]
