import React, { useContext, useState } from 'react'
import { AuthContext } from '../../contexts/auth'
import { UsersContext } from '../../contexts/users'
import { ModalContext } from '../../contexts/modal'
import Breadcrumbs from '../../components/breadcrumbs'
import Spinner from '../../components/spinner'
import ConfirmationModal from '../../components/confirmation-modal'

const Profile = () => {
  const { auth, authStatus } = useContext(AuthContext)
  const { me } = auth
  const { profileIsFetching } = authStatus
  const { requestPasswordReset } = useContext(UsersContext).usersMethods
  const { open, close, setContent } = useContext(ModalContext).modalMethods
  const [ message, setMessage ] = useState('')
  const delayClose = () => {
    setTimeout(()=>{
      close()
    },2000)
  }

  return (
    <div className="section">
      {profileIsFetching ? (
        <div className="columns is-vcentered is-fullheight">
          <div className="column has-text-centered">
            <Spinner size="3x" />
          </div>
        </div>
      ) : (
        <>
          <Breadcrumbs />
          <div className="box animated fadeIn">
            <h1>{me.display_name}</h1>
            <ul>
              <li>{ me.email }</li>
              <li><button
                className="button is-primary is-small"
                onClick={()=>{
                  setContent(()=>(
                    <ConfirmationModal
                      submit={async()=>{
                        const res = await requestPasswordReset(me.email)
                        if (res.ok) {
                          setContent(()=><div className="has-text-centered">'Check your email.'</div>)
                        } else {
                          setContent(()=><div className="has-text-centered">'Something went wrong...'</div>)
                        }
                        delayClose()
                      }}
                      cancel={()=>{
                        close()
                      }}
                    />
                  ))
                  open('100px')
                }}
              >Reset password</button> {message}</li>
            </ul>
          </div>
        </>
      )}
    </div>
  )
}

export default Profile
