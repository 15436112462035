import React, { createContext, useState, useEffect } from 'react'
import { io } from 'socket.io-client'

export const SocketContext = createContext()

const addr = `${process.env.REACT_APP_API}/cms`

const SocketProvider = props => {
  const [ socket, setSocket ] = useState(null)

  useEffect(()=>{
    setSocket(io(addr))
  },[])
  
  return (
    <SocketContext.Provider
      value={{ socket }}
    >
      {props.children}
    </SocketContext.Provider>
  )
}

export default SocketProvider
