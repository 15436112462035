import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import moment from 'moment'
import Utils from '../../utils'
import { AuthContext } from '../../contexts/auth'
import { BlogContext } from '../../contexts/blog'
import { ModalContext } from '../../contexts/modal'
import Spinner from '../../components/spinner'
import Breadcrumbs from '../../components/breadcrumbs'
import RichTextEditor from '../../components/rich-text-editor'
import ConfirmationModal from '../../components/confirmation-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faLock, faUnlock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const DEFAULT = {
  id: null,
  title: null,
  body: null,
  image: null,
  alt: null,
  route: null,
  description: null,
  locked: false,
  published_at: null,
}

const utils = new Utils()

const BlogCompose  = () => {
  const [ composing, setComposing ] = useState(DEFAULT) 
  const { blog, blogMethods, blogIsFetching } = useContext(BlogContext)
  const { pathname, search } = useLocation()
  const { id } = utils.getSearchObj(search)
  const { getBlog } = blogMethods

  useEffect(()=>{
    if (pathname.includes('edit') && id) {
      const filter = blog.filter(d=>d.id===id)

      if (!filter.length) {
        getBlog()
        return
      }

      const {
        title,
        body,
        image,
        alt,
        description,
        locked,
        route,
        published_at,
        canPublish,
      } = filter[0]

      setComposing({
        id,
        title,
        body,
        image,
        alt,
        description,
        route,
        locked,
        canPublish,
        published_at: moment(published_at).format('YYYY-MM-DD'),
      })
    }
  }, [blog])

  return blogIsFetching ? (
    <div className="columns is-vcentered is-fullheight">
      <div className="column has-text-centered">
        <Spinner size="3x" />
      </div>
    </div>
  ) : <BlogComposeForm { ...composing } />
}

const BlogComposeForm = ({
  id,
  title,
  body,
  route,
  image,
  alt,
  description,
  locked,
  published_at,
  canPublish,
}) => {
  const { me } = useContext(AuthContext).auth
  const history = useHistory()
  const {
    open,
    close,
    setContent,
  } = useContext(ModalContext).modalMethods
  const {
    getBlog,
    lockBlogPost,
    unlockBlogPost,
    deleteBlogPost,
  } = useContext(BlogContext).blogMethods
  const {
    createBlogPost,
    updateBlogPost,
  } = useContext(BlogContext).blogMethods
  const { pathname } = useLocation()

  const submit = e => {
    e.preventDefault()
    const body = utils.getFormData(e.target)
    let error = false
    Object.values(body).forEach(value=>{
      if (!value) error = true
    })
    if (!error) {
      if (pathname === '/blog/edit') {
        updateBlogPost(id,body)
      } else {
        createBlogPost(body)
      }
      history.push('/blog')
    }
  }

  const hideButtons = (pathname === '/blog/edit') ? '' : 'is-hidden'

  return (
    <div className="section">
      <div className="columns is-desktop is-mobile">
        <div className="column is-half">
          <Breadcrumbs />
        </div>
        <div className={`column is-half ${hideButtons}`}>
          <button
            className={`button is-danger is-small is-pulled-right ${locked ? 'is-hidden' : ''} m-l-sm`}
            onClick={()=>{
              setContent(()=>(
                <ConfirmationModal
                  submit={()=>{
                    deleteBlogPost(id)
                    close()
                    history.push('/blog')
                  }}
                  cancel={()=>{
                    close()
                  }}
                />
              ))
              open('300px')
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            type="button"
            className={`button ${locked?'is-success':'is-info'} is-small is-pulled-right`}
            onClick={async()=>{
              if (locked) {
                setContent(()=>(
                  <ConfirmationModal
                    submit={async ()=>{
                      await unlockBlogPost(id)
                      close()
                      getBlog()
                    }}
                    cancel={()=>{
                      close()
                    }}
                  />
                ))
                open('300px')
              } else {
                await lockBlogPost(id)
                getBlog()
              }
            }}
          >
            <FontAwesomeIcon icon={locked?faLock:faUnlock} />
          </button>
          <button
            type="button"
            className={`button is-disabled} is-small is-pulled-right m-r-sm`}
          >
            <FontAwesomeIcon icon={canPublish?faEyeSlash:faEye} />
          </button>
        </div>
      </div>
      <div className="box">
        <form id="blog-compose" onSubmit={submit}>
          <input type="hidden" name="author" value={me.id} className="input" />
          <div className="columns is-multiline">
            <div className="column is-full">
              <label className="label" htmlFor="published_at">Publish Date</label>
              <input className="input" type="date" name="published_at" defaultValue={published_at} disabled={locked} />
            </div>
            <div className="column is-full">
              <label className="label" htmlFor="title">Title</label>
              <input className="input" type="text" name="title" defaultValue={title} disabled={locked} />
            </div>
            <div className="column is-full">
              <label className="label" htmlFor="body">Body</label>
              <RichTextEditor name="body" defaultValue={body} locked={locked} />
            </div>
            <div className="column is-full">
              <label className="label" htmlFor="image">Image Path</label>
              <input
                className="input"
                type="text"
                name="image"
                defaultValue={image}
                disabled={locked}
              />
            </div>
            <div className="column is-full">
              <label className="label" htmlFor="route">Route</label>
              <div>
                <div
                  className="is-inline-block is-size-4 m-t-xs"
                >/blog/</div>
                <input
                  className="input is-inline"
                  style={{
                    width: 'calc(100% - 60px)',
                  }}
                  type="text"
                  name="route"
                  defaultValue={route}
                  disabled={locked}
                />
              </div>
            </div>
            <div className="column is-full">
              <label className="label" htmlFor="description">Description</label>
              <input
                className="input"
                type="text"
                name="description"
                defaultValue={description}
                disabled={locked}
              />
            </div>
            <div className="column is-full">
              <label className="label" htmlFor="alt">Alt</label>
              <input
                className="input"
                type="text"
                name="alt"
                defaultValue={alt}
                disabled={locked}
              />
            </div>
            <div className="column is-full has-text-right">
              <button
                className="button is-secondary m-r-sm"
                onClick={()=>{
                  history.push('/blog')
                }}
              >
                Cancel
              </button>
              <button
                className="button is-primary"
                disabled={locked}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default BlogCompose
