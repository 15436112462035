import React, { useContext } from 'react'
import { Route } from 'react-router-dom'
import { AuthContext } from '../../contexts/auth'

const PrivateRoute = (props) => {
  const {
    component,
    ...rest
  } = props
  const { isAuthenticated } = useContext(AuthContext).auth
  const Component = component
  return (<Route { ...rest } render={() => {
    let template = null
    if (isAuthenticated) {
      template = (
        <Component {...props} />
      )
    }
    return template
  }} /> )
}

export default PrivateRoute
