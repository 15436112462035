import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { BlogContext } from '../../contexts/blog'
import { ModalContext } from '../../contexts/modal'
import ConfirmationModal from '../../components/confirmation-modal'
import Breadcrumbs from '../../components/breadcrumbs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPoo, faTrash, faLock, faUnlock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import Spinner from '../../components/spinner'
import NewButton from '../../components/new-button'
import Service from '../../service'
import './index.scss'

const BlogList = () => {
  const { blog, blogIsFetching, blogMethods } = useContext(BlogContext) 
  const {
    getBlog,
    deleteBlogPost,
    lockBlogPost,
    unlockBlogPost,
  } = blogMethods
  const { open, close, setContent } = useContext(ModalContext).modalMethods;
  let template = null
  useEffect(()=>{
    getBlog()
  },[])
  if (blogIsFetching) template = (
    <div className="columns is-vcentered is-fullheight">
      <div className="column has-text-centered">
        <Spinner size="3x" />
      </div>
    </div>
  )
  else if (blog.length < 1) template = (
    <>
      <Breadcrumbs
        rightSide={(
          <Link
            to="blog/compose"
            className="is-pulled-right p-none"
          >
            <NewButton />
          </Link>
        )}
      />
      <EmptyBlogList />
    </>
  )
  else {
    template = (
      <>
        <Breadcrumbs
          rightSide={(
            <Link
              to="blog/compose"
              className="is-pulled-right p-none"
            >
              <NewButton />
            </Link>
          )}
        />
        {blog.map(d=>(
          <div className="columns" key={`blog-${d.id}`}>
            <div className="column">
              <div className="box blog-list-box">
                <p className="is-size-6 m-b-sm">
                  {moment(d.updated_at).format('M-D-YYYY hh:mmA')}
                </p>
                <p className="is-size-6">
                  <button
                    className={`button is-danger is-small is-pulled-right ${d.locked ? 'is-hidden' : ''} m-l-sm`}
                    onClick={()=>{
                      setContent(()=>(
                        <ConfirmationModal
                          submit={()=>{
                            deleteBlogPost(d.id)
                            close()
                          }}
                          cancel={()=>{
                            close()
                          }}
                        />
                      ))
                      open('300px')
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                  <button
                    type="button"
                    className={`button ${(d.locked)?'is-success':'is-info'} is-small is-pulled-right`}
                    onClick={async()=>{
                      if (d.locked) {
                        setContent(()=>(
                          <ConfirmationModal
                            submit={async ()=>{
                              await unlockBlogPost(d.id)
                              close()
                              getBlog()
                            }}
                            cancel={()=>{
                              close()
                            }}
                          />
                        ))
                        open('300px')
                      } else {
                        await lockBlogPost(d.id)
                        getBlog()
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={d.locked?faLock:faUnlock} />
                  </button>
                  <button
                    type="button"
                    className={`button is-disabled is-small is-pulled-right m-r-sm`}
                  >
                    <FontAwesomeIcon icon={d.canPublish?faEyeSlash:faEye} />
                  </button>
                </p>
                <Link
                  className="has-text-primary"
                  to={`/blog/edit?id=${d.id}`}
                >
                  <h1>
                    {d.title}
                  </h1>
                  <img src={`${Service.CDN}${d.image}`} alt={d.alt} className="preview-image" />
                </Link>
                <p className="is-size-6 break-word" dangerouslySetInnerHTML={{ __html: d.body}}></p>
              </div>
            </div>
          </div>
        ))}
      </>
    )
  }
  return (
    <div className="section">

      {template}
    </div>
  )
}

const EmptyBlogList = () => (
  <div className="columns is-multiline has-text-centered">
    <div className="column is-full">
      <FontAwesomeIcon icon={faPoo} size="6x" />
    </div>
    <div className="column is-full">
      <p className="is-size-3">You ain't said shit.</p>
      <p className="is-size-5">What you waitin' for?</p>
    </div>
    <div className="column is-full is-size-4">
      <Link
        to="/blog/compose"
      >
        <button
          type="button"
          className="button is-primary"
        >Speak Truth</button>
      </Link>
    </div>
  </div>
)

export default BlogList
