import React, { useContext } from 'react'
import { TooltipContext } from '../../contexts/tooltip'
import './index.scss' 

const Tooltip = () => {
  const {
    isActive,
    position,
    content,
    isReverse,
    tooltipRef,
  } = useContext(TooltipContext).tooltip
  const { width, height } = tooltipRef?.current?.getBoundingClientRect() || { width: 0, height: 0 }
  const adjust = isReverse ? width : 0
  return (
    <div
      className={`tooltip ${(isActive)?'is-active':''}`}
      style={{
        left: position.horizontal - adjust,
        top: position.vertical - height,
      }}
      ref={tooltipRef}
      id="tooltip"
    >
      <section className="tooltip-body">
        {content}
      </section>
    </div>
  )
}

export default Tooltip
