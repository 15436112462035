import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const NewButton = (props) => (
  <button
    className="button is-primary is-small has-text-weight-bold"
  >
    <FontAwesomeIcon icon={faPlus} className="m-r-xs" />
    New
  </button>
)

export default NewButton
