import React, { createContext, useState, useEffect, useRef } from 'react'

export const TooltipContext = createContext()

const DEFAULT_POSITION = {
  vertical: 0,
  horizontal: 0,
}

let timeout

const THRESHOLD = 20

const TooltipProvider = props => {
  const [ isActive, setIsActive ] = useState(false)
  const [ content, setContent ] = useState(null)
  const [ position, setPosition ] = useState(DEFAULT_POSITION)
  const [ isReverse, setIsReverse ] = useState(false)
  const tooltipRef = useRef()

  useEffect(()=>{
    const detectShouldClose = (e)=>{
      const rect = document.getElementById('tooltip').getBoundingClientRect()
      const { pageX, pageY } = e
      const isOverMenu = pageX > (rect.left + window.scrollX - THRESHOLD)
        && pageX < (rect.right + window.scrollX + THRESHOLD)
        && pageY > (rect.top + window.scrollY - THRESHOLD)
        && pageY < (rect.bottom + window.scrollY + THRESHOLD)
      clearTimeout(timeout)
      if (!isOverMenu) {
        timeout = setTimeout(()=>{
          setIsActive(false)
        },250)
      }
    }

    document.body.addEventListener('mousemove', detectShouldClose)

    return ()=>{
      document.body.removeEventListener('mousemove', detectShouldClose)
    }
  },[])

  const tooltip = {
    isActive,
    content,
    position,
    isReverse,
    tooltipRef,
  }

  const tooltipMethods = {
    show: () => {
      setIsActive(true)
    },
    hide: () => {
      setIsActive(false)
    },
    setContent,
    setPosition: (pos) => {
      const reverse = pos.horizontal > (window.innerWidth*0.75)
      setIsReverse(reverse)
      setPosition(pos)
    },
  }

  return (
    <TooltipContext.Provider
      value={{ tooltip, tooltipMethods }}
    >
      {props.children}
    </TooltipContext.Provider>
  )
}

export default TooltipProvider
