import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import key from './key.json'
import './index.scss'

const Breadcrumbs = ({ rightSide = null }) => {
  const { pathname } = useLocation()

  const getBreadcrumbs = () => {
    let pathArr = pathname.split('/')
    let template = []
    for (let i=1;i<pathArr.length;i++){
      const p = pathArr[i]
      if (p === '') continue
      let name = key[p] || 'Unkown'
      const isLast = (i>(pathArr.length-2))
      template.push(<li className={(isLast)?'has-text-weight-bold has-text-primary':''} key={name}>{name}</li>)
    }
    return template
  }

  return (
    <div className="animated fadeInDown is-hidden-mobile">
      <nav className="breadcrumb has-succeeds-separator m-b-md" aria-label="breadcrumbs">
        <div className="columns">
          <div className="column">
            <ul>
              <li><Link to="/" className="breadcrumb-link p-r-none">Home</Link></li>
              {getBreadcrumbs()}
            </ul>
          </div>
          <div className="column has-text-right">
            {rightSide}
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Breadcrumbs
