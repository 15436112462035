import React, { useContext, useRef, useEffect } from 'react'
import { useLocation, Link, useHistory } from 'react-router-dom'
import Utils from '../../utils'
import { AnalyticsContext } from '../../contexts/analytics'
import { ResponsiveContext } from '../../contexts/responsive'
import Breadcrumbs from '../../components/breadcrumbs'
import Spinner from '../../components/spinner'
import Engagement from '../../components/engagement'
import Insights from '../../components/insights'
import RealTime from '../../components/real-time'

const utils = new Utils()

const Home = () => {
  const { isRequesting } = useContext(AnalyticsContext).analyticsStatus
  const { breakpoint } = useContext(ResponsiveContext).responsive
  const { search } = useLocation()
  const { tab = 'engagement', time = '14daysAgo', site = 'all' } = utils.getSearchObj(search)
  const history = useHistory()

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevTime = usePrevious(time)
  const prevTab = usePrevious(tab)

  const prevSite = usePrevious(site)

  const isSmall = breakpoint === 'mobile' ? 'is-small' : ''

  const getAnalyticsComponent = () => {
    switch(tab){
      case 'engagement':
      default:
      return (
        <Engagement
          time={time}
          prevTime={prevTime}
          tab={tab}
          prevTab={prevTab}
          site={site}
          prevSite={prevSite}
        />
      )
      break
      case 'insights':
      return (
        <Insights
          time={time}
          prevTime={prevTime}
          tab={tab}
          prevTab={prevTab}
          site={site}
          prevSite={prevSite}
        />
      )
      break
      case 'real-time':
      return (
        <RealTime
          time={time}
          prevTime={prevTime}
          tab={tab}
          prevTab={prevTab}
          site={site}
          prevSite={prevSite}
        />
      )
      break
    }
  }

  return (
    <div className="section animated fadeIn">
      {(isRequesting)?(
        <div className="columns is-vcentered is-fullheight">
          <div className="column has-text-centered">
            <Spinner size="3x" />
          </div>
        </div>
      ):(
        <>
          <div className="columns is-desktop is-mobile m-b-none">
            <div className="column is-half-mobile is-two-thirds-desktop is-four-fifths-fullhd">
              <Breadcrumbs />
            </div>
            <div className="column is-half-mobile is-one-third-desktop is-one-fifth-fullhd">
              <div className={`tabs ${isSmall} is-boxed no-scroll`}>
                <ul>
                  <li className={(tab==='engagement')?'is-active':''}><Link to={`/?tab=engagement&time=${time}&site=${site}`}>Engagement</Link></li>
                  <li className={(tab==='insights')?'is-active':''}><Link to={`/?tab=insights&time=${time}&site=${site}`}>Insights</Link></li>
                  <li className={(tab==='real-time')?'is-active':''}><Link to={`/?tab=real-time&time=${time}&site=${site}`}>Real Time</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <div className={`select is-small m-b-md ${(tab==='real-time')?'is-hidden':''}`}>
            <label className="is-inline is-size-6">Showing data for last </label>
            <select
              value={time}
              onChange={(e)=>{
                const { value } = e.target
                history.push(`/?tab=${tab}&time=${value}&site=${site}`)
              }}
              className="is-inline is-size-6"
              style={{marginTop: -7}}
            >
              <option value="1daysAgo">1 day</option>
              <option value="2daysAgo">2 days</option>
              <option value="7daysAgo">7 days</option>
              <option value="14daysAgo">14 days</option>
              <option value="30daysAgo">1 month</option>
              <option value="90daysAgo">3 months</option>
              <option value="183daysAgo">6 months</option>
              <option value="365daysAgo">1 year</option>
            </select>
          </div>
          <div className={`select is-small m-b-md ${(tab==='real-time')?'is-hidden':''}`}>
            <label className="is-inline is-size-6"> in </label>
            <select
              value={site}
              onChange={(e)=>{
                const { value } = e.target
                history.push(`/?tab=${tab}&time=${time}&site=${value}`)
              }}
              className="is-inline is-size-6"
              style={{marginTop: -7}}
            >
              <option value="all">all sites</option>
              <option value="main">flagship</option>
              <option value="hypnotized">hypnotized</option>
            </select>
          </div>
          {getAnalyticsComponent()}
        </>
      )}
    </div>
  )
}

export default Home
