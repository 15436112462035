import React, { useContext } from 'react'
import { ModalContext } from '../../contexts/modal'
import './index.scss' 

const BasicModal = () => {
  const { isActive, content, width } = useContext(ModalContext).modal
  return (
    <div className={`basic-modal modal ${(isActive)?'is-active':''}`}>
      <div className="modal-background" />
      <div className="modal-card animated fadeIn" id="modal-card" style={{width}}>
        <section className="modal-card-body">
          {content}
        </section>
      </div>
    </div>
  )
}

export default BasicModal
