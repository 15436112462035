import React, { createContext, useState } from 'react'
import moment from 'moment'
import Service from '../service'

export const AnalyticsContext = createContext()

const getEngagementData = (data, time) => {
  const {
    timestamp,
  } = data
  const reports = getEngagementReports(data.reports, time)
  return {
    timestamp,
    reports,
  }
}

const getInsightsData = data => {
  const {
    timestamp,
  } = data
  const reports = getInsightsReports(data.reports)
  return {
    timestamp,
    reports,
  }
}

const getEngagementReports = (reports, time) => {
  const formatted = (
    Array.isArray(reports)
    && reports.length > 0
  ) ? reports.map(report=>getLineChartData(report, time)) : DEFAULT_REPORT
  return {
    sessions: formatted[0],
    interactivity: formatted[1],
    duration: formatted[2],
  }
}
const getInsightsReports = reports => {
  const formatted = (
    Array.isArray(reports)
    && reports.length > 0
  ) ? reports.map(report=>getBarChartData(report)) : DEFAULT_REPORT
  return {
    locations: formatted[0],
    technologies: formatted[1],
    devices: formatted[2],
    pages: formatted[3],
  }
}

const chartColors = [
  "#3e95cd",
  "#8e5ea2",
  "#3cba9f",
  "#e8c3b9",
  "#c45850",
]

const getDateTime = (datehour) => {
  const date = getDay(datehour.substr(0,8))
  const time = moment(datehour.substr(-2,2), 'HH').format('hha')
  return `${date} ${time}`
}
const getWeek = (weekNumber) => `${moment().day("Sunday").week(weekNumber).format('MM/DD')}-${moment().day("Saturday").week(weekNumber).format('MM/DD')}`
const getDay = (date) => moment(date).format('MM/DD')

const getLineChartData = (report, time) => {
  const {
    metricHeaders,
    rows,
  } = report
  let format;
  switch(time){
    case '1daysAgo':
    case '2daysAgo':
      format = getDateTime
    break
    case '7daysAgo':
    case '14daysAgo':
    case '30daysAgo':
      format = getDay
    break
    default:
      format = getWeek
    break
  }
  const labels = rows.map(row => format(row.dimensionValues[0]))
  const datasets = metricHeaders.map((mh, i) => ({
    data: rows.map(row=>row.metricValues[i]),
    label: mh,
    borderColor: chartColors[i],
    fill: false
  }))
  return {
    labels,
    datasets,
  }
}

const getLabel = (row, id) => {
  const { dimensionValues } = row
  switch(id){
    case 'locations':
    default:
    return `${dimensionValues[0]}, ${dimensionValues[1]}, ${dimensionValues[2]}` 
    case 'technologies':
    return `${dimensionValues[1]}, ${dimensionValues[2]}` 
    case 'devices':
    return `${dimensionValues[0].charAt(0).toUpperCase()}${dimensionValues[0].slice(1)}` 
    case 'pages':
    return `${dimensionValues[0]}${dimensionValues[1]}` 
  }
}
const getBarChartData = report => {
  const { rows, metricHeaders, id } = report
  const labels = rows.map((row => getLabel(row, id)))
  const datasets = metricHeaders.map((mh, i) => ({
    data: rows.map(row=>row.metricValues[i]),
    label: mh,
    backgroundColor: chartColors[i],
    borderColor: chartColors[i],
    borderWidth: 1,
  }))
  
  return {
    labels,
    datasets,
  }
}

const DEFAULT_REPORT = {
  labels: [],
  datasets: [],
}

const DEFAULT_ENGAGEMENT = {
  timestamp: null,
  reports: {
    sessions: DEFAULT_REPORT,
    interactivity: DEFAULT_REPORT,
    duration: DEFAULT_REPORT,
  }
}

const DEFAULT_INSIGHTS = {
  timestamp: null,
  reports: {
    locations: DEFAULT_REPORT,
    technologies: DEFAULT_REPORT,
    devices: DEFAULT_REPORT,
    pages: DEFAULT_REPORT,
  }
}

const DEFAULT_ANALYTICS = {
  engagement: DEFAULT_ENGAGEMENT,
  insights: DEFAULT_INSIGHTS,
}

const DEFAULT_SOCKETS = {
  billGodfrey: {
    name: 'Flagship',
    connected: [],
    earlierToday: [],
  },
  hypnotized: {
    name: 'Hypnotized',
    connected: [],
    earlierToday: [],
  }
}

const AnalyticsProvider = props =>{
  const [ sockets, setSockets ] = useState(DEFAULT_SOCKETS)
  const [ analytics, setAnalytics ] = useState(DEFAULT_ANALYTICS)
  const [ analyticsIsRequesting, setAnalyticsIsRequesting ] = useState(false)
  const analyticsMethods = {
    getEngagement: async (time, site) => {
      setAnalyticsIsRequesting(true)
      const res = await Service.getAnalytics('engagement', time, site)
      if (res.ok) {
        const data = await res.json()
        setAnalytics({
          ...analytics,
          engagement: getEngagementData(data, time)
        })
      }
      setAnalyticsIsRequesting(false)
    },
    getInsights: async (time, site) => {
      setAnalyticsIsRequesting(true)
      const res = await Service.getAnalytics('insights', time, site)
      if (res.ok) {
        const data = await res.json()
        setAnalytics({
          ...analytics,
          insights: getInsightsData(data)
        })
      }
      setAnalyticsIsRequesting(false)
    },
    getSockets: async () => {
      const res = await Service.getSockets()
      if (res.ok) {
        const { connected, earlierToday } = await res.json()
        setSockets({
          billGodfrey: {
            ...sockets.billGodfrey,
            connected: connected.billGodfrey,
            earlierToday: earlierToday.billGodfrey,
          },
          hypnotized: {
            ...sockets.hypnotized,
            connected: connected.hypnotized,
            earlierToday: earlierToday.hypnotized,
          },
        })
      }
    },
  }
  const analyticsStatus = {
    isRequesting: analyticsIsRequesting,
  }
  return (
    <AnalyticsContext.Provider value={{ analytics, sockets, analyticsMethods, analyticsStatus }} >
      { props.children }
    </AnalyticsContext.Provider>
  )
}

export default AnalyticsProvider
