import React, { useContext, useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { SeoContext } from '../../contexts/seo'
import { ModalContext } from '../../contexts/modal'
import ConfirmationModal from '../../components/confirmation-modal'
import Breadcrumbs from '../../components/breadcrumbs'
import Spinner from '../../components/spinner'
import NewButton from '../../components/new-button'
import Service from '../../service'
import './index.scss'

const SeoList = () => {
  const {
    seo,
    seoMethods,
  } = useContext(SeoContext)

  const {
    open,
    close,
    setContent,
  } = useContext(ModalContext).modalMethods

  const {
    getSeoRoutes,
    deleteSeoRoute,
    updateSeoRoute,
    publishSeo,
  } = seoMethods
  const {
    seoRoutes,
    isRequesting,
    isBuilding,
  } = seo
  const [ editingRoutes, setEditingRoutes ] = useState([])

  useEffect(() => {
    getSeoRoutes()
  },[])

  const startEdit = (routeId) => {
    const updatedRoutes = [ routeId, ...editingRoutes ]
    setEditingRoutes(updatedRoutes)
  }

  const finishEdit = (routeId) => {
    const index = editingRoutes.indexOf(routeId)
    const updatedRoutes = [
      ...editingRoutes.slice(0,index),
      ...editingRoutes.slice(index+1)
    ]
    setEditingRoutes(updatedRoutes)
  }

  const saveEdit = (routeId) => {
    const elements = Array.from(
      document.getElementsByClassName(`data-${routeId}`)
    )
    let body = {
      id: routeId,
    }
    elements.forEach(element=>{
      body[element.name] = element.value
    })
    const old = seoRoutes.find(r => r.id === routeId)
    const somethingChanged = (
      body.route !== old.route
      || body.title !== old.title
      || body.description !== old.description
      || body.image !== old.image
      || body.og_type !== old.og_type
    )
    if (somethingChanged) updateSeoRoute(body)
    finishEdit(routeId)
  }

  let isPublished = true
  seoRoutes.forEach(route => {
    if (
      !route.published_at
      || route.published_at < route.modified_at
    ) isPublished = false
  })

  return (
    <div className="section">
      {isRequesting || isBuilding ? (
        <div className="columns is-vcentered is-fullheight">
          <div className="column has-text-centered">
            <Spinner size="3x" />
          </div>
        </div>
      ):(
        <>
          <div className="columns is-desktop is-mobile">
            <div className="column">
              <Breadcrumbs />
              <button
                className="button is-primary is-small"
                type="button"
                onClick={()=>{
                  setContent(()=>(
                    <ConfirmationModal
                      msg="Site will rebuild, causing 1-2 minutes of downtime. Are you sure?"
                      submit={()=>{
                        publishSeo()
                        close()
                      }}
                      cancel={()=>{
                        close()
                      }}
                    />
                  ))
                  open('300px')
                }}
                disabled={isPublished}
              >Publish</button>
              <Link
                to="seo/create"
                className="is-pulled-right p-none"
              >
                <NewButton />
              </Link>
            </div>
          </div>
          <div className="box animated fadeIn is-paddingless">
            <table className="table is-bordered is-striped is-fullwidth seo-list">
              <thead>
                <tr>
                  <th style={{width: '38px'}}></th>
                  <th style={{width: '38px'}}></th>
                  <th>
                    Route
                  </th>
                  <th>
                    Title
                  </th>
                  <th className="is-hidden-mobile">
                    Description
                  </th>
                  <th className="is-hidden-mobile">
                    Image
                  </th>
                  <th className="is-hidden-mobile">
                    OG Type
                  </th>
                </tr>
              </thead>
              <tbody>
                { seoRoutes.map(route => {
                  const rowIsPublished = route.published_at && route.published_at > route.modified_at
                  const isEditing = editingRoutes.includes(route.id)
                  return (
                    <tr className={`${rowIsPublished ? '' : 'has-text-danger'}`} key={`route-${route.id}`}>
                      <td>
                        <button
                          type="button"
                          className={`button is-primary is-small ${isEditing ? 'is-hidden' : ''}`}
                          onClick={startEdit.bind(null, route.id)}
                        >Edit</button>
                        <button
                          type="button"
                          className={`button is-info is-small ${!isEditing ? 'is-hidden' : ''}`}
                          onClick={saveEdit.bind(null, route.id)}
                        >Save</button>
                      </td>
                      <td>
                        <button
                          type="button"
                          className={`button is-danger is-small ${isEditing ? 'is-hidden' : ''}`}
                          onClick={()=>{
                            setContent(()=>(
                              <ConfirmationModal
                                submit={()=>{
                                  deleteSeoRoute(route.id)
                                  close()
                                }}
                                cancel={()=>{
                                  close()
                                }}
                              />
                            ))
                            open('300px')
                          }}
                        >Delete</button>
                        <button
                          type="button"
                          className={`button is-danger is-small ${!isEditing ? 'is-hidden' : ''}`}
                          onClick={finishEdit.bind(null, route.id)}
                        >Cancel</button>
                      </td>
                      <td>
                        <p className={isEditing ? 'is-hidden' : ''}>{route.route}</p>
                        <input type="text" name="route" defaultValue={route.route} className={`input ${!isEditing ? 'is-hidden' : ''} data-${route.id}`} />
                      </td>
                      <td>
                        <p className={isEditing ? 'is-hidden' : ''}>{route.title}</p>
                        <input type="text" name="title" defaultValue={route.title} className={`input ${!isEditing ? 'is-hidden' : ''} data-${route.id}`} />
                      </td>
                      <td className="is-hidden-mobile">
                        <p className={isEditing ? 'is-hidden' : ''}>{route.description}</p>
                        <textarea name="description" className={`textarea ${!isEditing ? 'is-hidden' : ''} data-${route.id}`} defaultValue={route.description} />
                      </td>
                      <td className="is-hidden-mobile">
                        <img
                          src={`${Service.CDN}${route.image}`}
                          className={isEditing ? 'is-hidden' : ''}
                          style={{ width: '100px', margin: '0 auto' }}
                        />
                        <input type="text" name="image" defaultValue={route.image}  className={`input ${!isEditing ? 'is-hidden' : ''} data-${route.id}`} />
                      </td>
                      <td className="is-hidden-mobile">
                        <p className={isEditing ? 'is-hidden' : ''}>{route.og_type}</p>
                        <input type="text" name="og_type" defaultValue={route.og_type} className={`input ${!isEditing ? 'is-hidden' : ''} data-${route.id}`} />
                      </td>
                    </tr>
                  )
                }) }
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  )
}

export default SeoList
