import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ResponsiveContext } from './responsive'

export const SidebarContext = createContext()

const DEFAULT = {
  isOpen: false,
  isFloating: false,
}

const SidebarProvider = props => {
  const [ sidebar, setSidebar ] = useState(DEFAULT)
  const { breakpoint } = useContext(ResponsiveContext).responsive
  const { pathname } = useLocation()

  useEffect(()=>{
    switch (breakpoint) {
      case 'mobile':
      case 'tablet':
        setSidebar({
          ...sidebar,
          isFloating: true,
        })
      break
      case 'desktop':
      case 'widescreen':
      case 'fullhd':
      default:
        setSidebar({
          ...sidebar,
          isFloating: false,
        })
      break
    }
  },[breakpoint])

  useEffect(()=>{
    setSidebar({
      ...sidebar,
      isOpen: false,
    })
  },[pathname])

  const toggleIsOpen = ()=>{
    setSidebar({
      ...sidebar,
      isOpen: !sidebar.isOpen,
    })
  }

  return (
    <SidebarContext.Provider
      value={{ sidebar, toggleIsOpen }}
    >
      {props.children}
    </SidebarContext.Provider>
  )
}

export default SidebarProvider
