import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'

export default class Paginator extends React.Component {
  shouldComponentUpdate(nextProps){
    let { action } = nextProps
    if (nextProps.page !== this.props.page){
      action(nextProps.page)
    }
    return true
  }

  getTotalPages(){
    let { count, limit } = this.props
    return Math.ceil(count / limit)
  }

  getPaginationList(){
    let { action, page } = this.props
    let template = []
    let totalPages = this.getTotalPages()

    if (totalPages > 6) {
      let start = page - 1
      if (page === 1 || page === 2) {
        start = 2
      } else if (page === totalPages) {
        start = page - 3
      } else if (page === totalPages-1) {
        start = page - 2
      }

      template.push(
        <ul key={0} className="pagination-list">
          <li>
            <button 
              className={`pagination-link pointer ${(page===1)?'is-current':''}`}
              aria-label="Goto page 1"
              onClick={()=>{action(1)}}
            >1</button>
          </li>
          <li><span className="pagination-ellipsis">&hellip;</span></li>
          <li>
            <button
              className={`pagination-link pointer ${(page===start)?'is-current':''}`}
              aria-label={`Goto page ${start}`}
              onClick={()=>{action(start)}}
            >{start}</button>
          </li>
          <li>
            <button
              className={`pagination-link pointer ${(page===start+1)?'is-current':''}`}
              aria-label={`Goto page ${start+1}`}
              onClick={()=>{action(start+1)}}
            >{start+1}</button>
          </li>
          <li>
            <button
              className={`pagination-link pointer ${(page===start+2)?'is-current':''}`}
              aria-label={`Goto page ${start+2}`}
              onClick={()=>{action(start+2)}}
            >{start+2}</button>
          </li>
          <li><span className="pagination-ellipsis">&hellip;</span></li>
          <li>
            <button 
              className={`pagination-link pointer ${(page===totalPages)?'is-current':''}`}
              aria-label={`Goto page ${totalPages}`}
              onClick={()=>{action(totalPages)}}
            >{totalPages}</button>
          </li> 
        </ul>
      )
    } else {
      for ( let i=1; i <= totalPages; i+=1 ){
        template.push(
          <li key={i}>
            <button 
              className={`pagination-link pointer ${(page===i)?'is-current':''}`} 
              aria-label={`Goto page ${i}`}
              onClick={()=>{action(i)}}
            >{i}</button>
          </li>
        )
      }
    }

    return template
  }

  previousClicked(){
    let { page, action } = this.props
    if (page > 1) action(page-1)
  }

  nextClicked(){
    let { page, action } = this.props
    let totalPages = this.getTotalPages()
    if (page < totalPages) action(page+1)
  }

  render(){
    let { page, className, count, limit } = this.props
    let pageLimit = this.getTotalPages() - 1
    let display = (pageLimit < 1) ? {display: 'none'} : {display: ''}
    let cn = className || ''
    const start = 1 + ((page-1) * limit)
    const end = Math.min(page * limit, count)
    return (
      <div className={`paginator-container ${cn}`} style={display}>
        <div className="columns is-vcentered">
          <div className="column is-hidden-mobile">
            <p className="p-l-md">Showing {start} to {end} of {count} entries.</p>
          </div>
          <div className="column is-narrow">
            <nav className="pagination is-centered" role="navigation" aria-label="pagination">
              <button className="pagination-previous pointer button is-info" onClick={this.previousClicked.bind(this)} disabled={(page<2)}>Prev</button>
              <ul className="pagination-list">
                {this.getPaginationList()}
              </ul>
              <button className="pagination-next pointer button is-info" onClick={this.nextClicked.bind(this)} disabled={(page > pageLimit)}>Next</button>
            </nav>
          </div>
        </div>
      </div>
    )
  }
}

Paginator.propTypes = {
  count: PropTypes.number,
  limit: PropTypes.number,
  page: PropTypes.number,
  action: PropTypes.func,
  className: PropTypes.string,
}
