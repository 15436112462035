import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { MediaContext } from '../../contexts/media'
import { ModalContext } from '../../contexts/modal'
import ConfirmationModal from '../../components/confirmation-modal'
import Breadcrumbs from '../../components/breadcrumbs'
import OrderableImages from '../../components/orderable-images'
import UploadImages from '../../components/upload-images'
import ImportImages from '../../components/import-images'
import Utils from '../../utils'

const { getSearchObj } = new Utils()

function MediaCollection () {
  const {
    mediaCollections,
    mediaDestinations,
    mediaImported,
    activeMediaCollection,
    mediaMethods: {
      getMediaCollections,
      getMediaDestinations,
      createMediaCollection,
      updateMediaCollection,
      removeMediaFromCollection,
      setMediaImported,
      setActiveMediaCollection,
    },
  } = useContext(MediaContext);

  const { open, close, setContent } = useContext(ModalContext).modalMethods

  const [ selectedDestination, setSelectedDestination ] = useState('none')
  const [ hasFetched, setHasFetched ] = useState(false)
  const [ title, setTitle ] = useState('')

  useEffect(()=>{
    getMediaCollections()
    getMediaDestinations()
    return ()=>{
      setMediaImported([])
      setActiveMediaCollection(null)
    }
  },[])

  const { search } = useLocation()
  const history = useHistory()
  const { id } = getSearchObj(search)

  useEffect(()=>{
    const foundCollection = mediaCollections.find(collection => collection.id === id)
    if (foundCollection && !activeMediaCollection?.id && activeMediaCollection?.id !== foundCollection?.id){
      setActiveMediaCollection(foundCollection)
      setMediaImported([
        ...mediaImported,
        ...foundCollection.content,
      ])
    }
  },[mediaCollections])

  let heading = 'Update Media Collection'
  if (!id) heading = 'Create Media Collection'
  else if (!hasFetched && activeMediaCollection?.id && selectedDestination !== activeMediaCollection.assigned_to.id) {
    setTitle(activeMediaCollection.title)
    setSelectedDestination(activeMediaCollection.assigned_to.id || 'none')
    setHasFetched(true)
  }

  const save = () => {
    const dest = (selectedDestination === 'none') ? null : selectedDestination
    if (id)
      updateMediaCollection(id, mediaImported, title, selectedDestination)
    else
      createMediaCollection(mediaImported, title, selectedDestination)
  }

  const hasChanges = (
    (
      activeMediaCollection?.assigned_to?.id
      && activeMediaCollection?.assigned_to?.id !== selectedDestination
    )
    || (
      !activeMediaCollection?.assigned_to?.id
      && selectedDestination !== 'none'
    )
    || activeMediaCollection?.title !== title
    || JSON.stringify(activeMediaCollection?.content) !== JSON.stringify(mediaImported)
  )

  return (
    <div className="section">
      <Breadcrumbs
        rightSide={(
          <>
            <button
              className="button is-secondary m-r-sm"
              onClick={()=>{
                history.push('/media')
              }}
            >Cancel</button>
            <button
              className="button is-primary"
              disabled={!hasChanges}
              onClick={()=>{
                setContent(()=>(
                  <ConfirmationModal
                    submit={()=>{
                      save()
                      close()
                      history.push('/media')
                    }}
                    cancel={()=>{
                      close()
                    }}
                  />
                ))
                open('300px')
              }}
            >Save</button>
          </>
        )}
      />
      <div className="box">
        <h3 className="m-b-md has-text-primary">{heading}</h3>
        <div className="columns">
          <div className="column">
            <input
              type="text"
              className="input"
              value={title}
              onChange={(e)=>{
                const { value } = e.target
                setTitle(value)
              }}
            />
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <div className="select is-fullwidth">
              <select
                value={selectedDestination}
                onChange={(e)=>{
                  setSelectedDestination(e.target.value)
                }}
              >
                <option value="none">None</option>
                {mediaDestinations?.map(destination=>(
                  <option
                    value={destination.id}
                    key={`media-destination-${destination.id}`}
                  >{destination.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {mediaImported.length ? (
              <OrderableImages
                images={mediaImported}
                onDragEnd={(items)=>{
                  const files = items.map(item=>item.src)
                  setMediaImported(files)
                }}
                onRemove={(item)=>{
                  const filteredFiles = mediaImported.filter(file => file !== item.src)
                  setMediaImported(filteredFiles)
                }}
              />
            ):(
              <h3>This collection is empty.</h3>
            )}
          </div>
        </div>
      </div>
      <ImportImages />
      <UploadImages />
    </div>
  )
}

export default MediaCollection
