import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/auth";

export default function SsoGoogle () {
    const { 
        authStatus: {
            ssoIsFetching,
        }, 
        authMethods: {
            loginGoogle,
        }, 
    } = useContext(AuthContext)
    useEffect(() => {
        const qs = new URLSearchParams(window.location.search)
        const code = qs.get('code')
        loginGoogle(code)
    }, [])
    return (
        <div>
        </div>
    )
}
