import React, { Component } from 'react';
import { EditorState, convertToRaw, convertFromHTML, ContentState, CompositeDecorator } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './index.scss'


function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

const Link = (props) => {
  const {url} = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url}>
      {props.children}
    </a>
  );
};

function findImageEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'IMAGE'
      );
    },
    callback
  );
}

const Image = (props) => {
  const {
    height,
    src,
    width,
  } = props.contentState.getEntity(props.entityKey).getData();

  return (
    <img src={src} height={height} width={width} />
  );
};

export default class RichTextEditor extends Component {
  state = {
    hasUpdated: false,
    editorState: EditorState.createEmpty(),
  }

  componentDidMount(){
    const { defaultValue } = this.props
    const markup = defaultValue ? defaultValue : ''
    const blocksFromHTML = convertFromHTML(markup);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );


    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
      {
        strategy: findImageEntities,
        component: Image,
      },
    ]);

    this.setState({
      hasUpdated: true,
      editorState: EditorState.createWithContent(
        state,
        decorator,
      ),
    })
  }

  componentDidUpdate(prevProps){
    const { defaultValue } = this.props
    if (prevProps.defaultValue !== defaultValue) {
      const markup = defaultValue ? defaultValue : ''
      const blocksFromHTML = convertFromHTML(markup);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );

      const decorator = new CompositeDecorator([
        {
          strategy: findLinkEntities,
          component: Link,
        },
        {
          strategy: findImageEntities,
          component: Image,
        },
      ]);
      this.setState({
        hasUpdated: true,
        editorState: EditorState.createWithContent(
          state,
          decorator,
        ),
      })
    }
  }

  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
      hasUpdated: true,
    });
  };

  getImages(){
    const el = document.createElement('html')
    el.innerHTML = this.getHTML(this.state.editorState,false)
    const collection = el.getElementsByTagName('IMG')
    let arr = []
    for (let i=0;i<collection.length;i+=1){
      let image = collection[i]
      if (!image.alt || image.alt === 'undefined') image.alt = 'Bill Godfrey'
      if (!image.style.height) image.style.height = 'auto'
      if (!image.style.width) image.style.width = 'auto'
      arr.push(image)
    }
    return arr
  }

  appendImageDataToRaw(raw){
    const images = this.getImages()
    let imageIndex = -1
    for (const i in raw.entityMap) {
      const entity = raw.entityMap[i]
      if (entity.type === "IMAGE") {
        imageIndex += 1
        if (!images[imageIndex]) continue
        raw.entityMap[i] = {
          ...entity,
          data: {
            ...entity.data,
            alt: (images[imageIndex].alt && images[imageIndex].alt !== 'undefined') ? images[imageIndex].alt : 'Bill Godfrey',
            height: images[imageIndex].style.height,
            width: images[imageIndex].style.width,
          }
        }
      }
    }
    return raw 
  }

  getHTML(editorState, appendImageData){
    const content = editorState.getCurrentContent()
    const raw = (appendImageData) ? this.appendImageDataToRaw(convertToRaw(content)) : convertToRaw(content)
    return draftToHtml(raw)
  }

  render() {
    const { editorState } = this.state;
    const html = this.getHTML(editorState, true)
    return (
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="input rich-text-editor"
          onEditorStateChange={this.onEditorStateChange}
          onFocus={e=>e.target.parentNode.parentNode.parentNode.classList.add('is-focused')}
          onBlur={e=>e.target.parentNode.parentNode.parentNode.classList.remove('is-focused')}
          toolbar={{
            image: {
              alt: {
                present: true,
                mandatory: true,
              }
            }
          }}
          readOnly={this.props.locked}
        />
        <textarea
          disabled
          value={html}
          className="input"
          name={this.props.name}
          style={{minHeight: 200}}
        />
      </div>
    );
  }
}
