import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import BlogList from '../views/blog-list'
import BlogCompose from '../views/blog-compose'
import Media from '../views/media'
import MediaCollection from '../views/media-collection'
import SocketProvider from '../contexts/socket'
import BlogProvider from '../contexts/blog'
import UsersProvider from '../contexts/users'
import AuthProvider from '../contexts/auth'
import ModalProvider from '../contexts/modal'
import TooltipProvider from '../contexts/tooltip'
import SidebarProvider, { SidebarContext } from '../contexts/sidebar'
import AnalyticsProvider from '../contexts/analytics'
import ResponsiveProvider from '../contexts/responsive'
import MediaProvider from '../contexts/media'
import SeoProvider from '../contexts/seo'
import UsersList from '../views/users-list'
import UsersCreate from '../views/users-create'
import Profile from '../views/profile'
import SeoList from '../views/seo-list'
import SeoCreate from '../views/seo-create'
import Home from '../views/home'
import PrivateRoute from '../components/private-route'
import Sidebar from '../components/sidebar'
import BasicModal from '../components/basic-modal'
import Tooltip from '../components/tooltip'
import SsoGoogle from '../views/sso-google'
import 'animate.css'
import './index.sass'

const App = () => {
  return (
    <BrowserRouter>
      <ResponsiveProvider>
        <SocketProvider>
          <SidebarProvider>
            <ModalProvider>
              <TooltipProvider>
                <AuthProvider>
                  <SeoProvider>
                    <UsersProvider>
                      <BlogProvider>
                        <AnalyticsProvider>
                          <MediaProvider>
                            <SidebarContext.Consumer>
                              {({sidebar, toggleIsOpen}) => {
                                return (
                                  <>
                                    <main className={`main ${(sidebar.isFloating)?'':'with-padding'}`}>
                                      <button
                                        type="button"
                                        className={`button primary sidebar-toggler ${(sidebar.isFloating)?'':'is-hidden'}`}
                                        onClick={()=>{
                                          toggleIsOpen()
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faBars} />
                                      </button>
                                      <PrivateRoute exact path="/" component={Home} />
                                      <PrivateRoute exact path="/blog" component={BlogList} />
                                      <PrivateRoute exact path="/blog/compose" component={BlogCompose} />
                                      <PrivateRoute exact path="/blog/edit" component={BlogCompose} />
                                      <PrivateRoute exact path="/media" component={Media} />
                                      <PrivateRoute exact path="/media-collection" component={MediaCollection} />
                                      <PrivateRoute exact path="/users" component={UsersList} />
                                      <PrivateRoute exact path="/users/create" component={UsersCreate} />
                                      <PrivateRoute exact path="/profile" component={Profile} />
                                      <PrivateRoute exact path="/seo" component={SeoList} />
                                      <PrivateRoute exact path="/seo/create" component={SeoCreate} />
                                      <Route exact path="/sso-google" component={SsoGoogle} />

                                    </main>
                                    <BasicModal />
                                    <Sidebar />
                                    <Tooltip />
                                  </>
                                )
                              }}
                            </SidebarContext.Consumer>
                          </MediaProvider>
                        </AnalyticsProvider>
                      </BlogProvider>
                    </UsersProvider>
                  </SeoProvider>
                </AuthProvider>
              </TooltipProvider>
            </ModalProvider>
          </SidebarProvider>
        </SocketProvider>
      </ResponsiveProvider>
    </BrowserRouter>
  )
}

export default App
