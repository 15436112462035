import React, { createContext, useState } from 'react'
import Service from '../service'

export const SeoContext = createContext()

const SeoProvider = props => {
  const [ seoRoutes, setSeoRoutes ] = useState([])
  const [ error, setError ] = useState(null)
  const [ isRequesting, setIsRequesting ] = useState(false)
  const [ isBuilding, setIsBuilding ] = useState(false)

  const seoMethods = {
    publishSeo: async () => {
      setIsBuilding(true)
      const res = await Service.publishSeo()
      const data = await res.json()
      if (res.ok) {
        const { routes } = data
        setSeoRoutes(routes)
      }
      setIsBuilding(false)
    },
    getSeoRoutes: async () => {
      setIsRequesting(true)
      const res = await Service.getSeoRoutes()
      const data = await res.json()
      if (res.ok) {
        const { routes } = data
        setSeoRoutes(routes)
      }
      setIsRequesting(false)
    },
    createSeoRoute: async (body) => {
      setIsRequesting(true)
      const res = await Service.createSeoRoute(body)
      const data = await res.json()
      if (res.ok) {
        const { routes } = data
        setSeoRoutes(routes)
      } else {
        const { message } = data
        setError(message)
      }
      setIsRequesting(false)
    },
    updateSeoRoute: async (body) => {
      const res = await Service.updateSeoRoute(body)
      const data = await res.json()
      if (res.ok) {
        const { routes } = data
        setSeoRoutes(routes)
      } else {
        const { message } = data
        setError(message)
      }
    },
    deleteSeoRoute: async (id) => {
      setIsRequesting(true)
      const res = await Service.deleteSeoRoute(id)
      const data = await res.json()
      if (res.ok) {
        const { routes } = data
        setSeoRoutes(routes)
      } else {
        const { message } = data
        setError(message)
      }
      setIsRequesting(false)
    },
  }

  const seo = {
    seoRoutes,
    error,
    isRequesting,
    isBuilding,
  }

  return (
    <SeoContext.Provider
      value={{ seo, seoMethods }}
    >
      {props.children}
    </SeoContext.Provider>
  )
}

export default SeoProvider
