import React, { useContext, useEffect } from 'react'
import moment from 'moment'
import { AnalyticsContext } from '../../contexts/analytics'
import { ResponsiveContext } from '../../contexts/responsive'
import { SocketContext } from '../../contexts/socket'

const RealTime = ({ tab, prevTab, }) => {
  const {
    sockets,
    analyticsMethods,
  } = useContext(AnalyticsContext)

  const {
    socket,
  } = useContext(SocketContext)

  const {
    breakpoint,
  } = useContext(ResponsiveContext).responsive

  const { getSockets } = analyticsMethods

  useEffect(()=>{
    if ( 
      tab !== prevTab
      && tab === 'real-time'
    ) {
      getSockets()
    }
  },[tab])

  useEffect(()=>{
    if (socket) {
      socket.on('update',()=>{
        getSockets()
      })
    }
    return ()=>{
      socket.off('update')
    }
  },[socket])

  const getTable = (sockets, hideDisconnected=true) => {
    const ipColumnStyle = (
      breakpoint === 'mobile'
      || breakpoint === 'tablet'
    ) ? {
      overflow: 'hidden',
      maxWidth: 20,
    } : null
    return(
      <table className="table is-bordered is-striped is-fullwidth">
        <thead>
          <tr>
            <th className="is-narrow" style={ipColumnStyle}>IP Address</th>
            <th className="is-narrow">Path</th>
            <th className="is-narrow"># Visited</th>
            <th className="is-narrow">Time in</th>
            <th className={`is-narrow ${hideDisconnected ? 'is-hidden' : ''}`}>Time out</th>
          </tr>
        </thead>
        <tbody>
          {sockets.map(({
            id,
            ip_address,
            pathname,
            pages_viewed,
            created_at,
            disconnected_at,
          })=>{
            created_at = moment(created_at).format('LTS')
            disconnected_at = disconnected_at ? moment(disconnected_at).format('LTS') : ''
            ip_address = ip_address.split(',')[0]
            return (
              <tr key={id}>
                <td style={ipColumnStyle}>
                  <a
                    href={`https://whatismyipaddress.com/ip/${ip_address}`}
                    target="_blank"
                  >
                    {ip_address}
                  </a>
                </td>
                <td>{pathname}</td>
                <td>{pages_viewed}</td>
                <td>{created_at}</td>
                <td className={hideDisconnected ? 'is-hidden' : ''}>{disconnected_at}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  return (
    <div className="columns is-multiline">
      {
        Object.keys(sockets).map(key=>(
          <div className="column is-half">
            <div className="card">
              <div className="card-content">
                <h1>{sockets[key].name}</h1>
                <h3>{sockets[key].connected.length} connected</h3>
                {sockets[key].connected.length ? getTable(sockets[key].connected):null}
                <br />
                <h3>{sockets[key].earlierToday.length} earlier today</h3>
                {sockets[key].earlierToday.length ? getTable(sockets[key].earlierToday, false):null}
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default RealTime
