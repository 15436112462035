import React, { useState, useContext, useRef } from 'react'
import { MediaContext } from '../../contexts/media'
export default function UploadImages () {
  const {
    mediaMethods: {
      uploadMedia,
      getMedia,
      getMediaCollections,
    },
  } = useContext(MediaContext)
  const [ file, setFile ] = useState(null)
  const btnRef = useRef()
  return (
    <div className="box">
      <h3 className="m-b-md has-text-primary">Upload new image</h3>
      <form
        onSubmit={(e)=>{
          btnRef.current.disabled = true
          e.preventDefault()
          const file = e.target[0].files[0]
          if (!file) return
          uploadMedia(file, file.name).then(()=>{
            btnRef.current.disabled = false
            getMedia()
            getMediaCollections()
            e.target.reset()
            setFile(null)
          })
        }}
        className="form"
      >
        <div className="field is-grouped">
          <div className="control">
            <input
              type="file"
              name="image-file"
              onChange={(e)=>{
                setFile(e.target.value)  
              }}
              accept="image/*"
              className="m-t-sm"
            /> 
          </div>
          <div className="control">
            <button className="button is-primary" disabled={!file} ref={btnRef}>Upload</button>
          </div>
        </div>
      </form>
    </div>

  )
}
