import moment from 'moment'

const DISPLAY_NONE = {display: 'none'}
const DISPLAY_BLOCK = {display: 'block'}

export default class Utils {
  /**
    Convert an object in to a query string.
    @param obj - Some object.  
  **/
  makeQueryString(obj){
    let str = ''
    let i=0
    for (let key in obj){
      let data = obj[key]
      let piece = ''

      if (data === null) continue

      if (typeof data === 'string' || typeof data === 'number') {
        let c = '&'
        if (i===0) c = '?'
        piece = `${c}${key}=${data}`

      } else if (Array.isArray(data) && data.length > 0) {
        let a = ''

        for (let o=0;o<data.length;o++){
          if (o>0) a += ','

          a += data[o]
        }

        piece = `&${key}=${a}`
      }


      str += piece
      i += 1
    }

    return str
  }

  /**
    Copy a string to the clipboard.
    @param str - any string
  **/
  copyStringToClipboard (str) {
    // Create new element
    var el = document.createElement('textarea')
    // Set value (string to be copied)
    el.value = str
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '')
    el.style = {position: 'absolute', left: '-9999px'}
    document.body.appendChild(el)
    // Select text inside element
    el.select()
    // Copy text to clipboard
    document.execCommand('copy')
    // Remove temporary element
    document.body.removeChild(el)
  }

  isDateString(str){
    const regex = new RegExp(/^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/g)
    return regex.test(str)
  }

  /**
    Converts a boolean value to {display: 'block'} or {display: 'none'} for easy use in the style attr's of React components

    @param shouldUse - boolean
  **/
  getDisplay(shouldUse, d=DISPLAY_BLOCK){
    return (shouldUse) ? d : DISPLAY_NONE
  }

  /**
    Converts an ISO date value in to month and day, like 'Aug 23rd'

    @param date - ISO date string
  **/
  getShortDate(date){
    if (!this.isDateString(date)) return false
    let m = moment(date)
    return m.format('MMM D')
  }

  /**
    Converts an ISO date value in to month day and year, like 'Aug 23rd, 2019'

    @param date - ISO date string
  **/
  getMediumDate(date){
    if (!this.isDateString(date)) return false
    let m = moment(date)
    return m.format('MMM D, YYYY')
  }

  /**
    Converts an ISO date value in to digital clock time, like '1:56PM'

    @param date - ISO date string
  **/
  getShortTime(date){
    if (!this.isDateString(date)) return false
    let m = moment(date)
    return m.format('h:mmA')
  }

  /**
    Tells the approximate distance between two ISO dates
    @param to - ISO date string
    @param from - ISO date string
  **/
  getTimeUntil(to, from){
    let a = (this.isDateString(from)) ? moment(from) : moment()
    let b = (this.isDateString(to)) ? moment(to) : moment()
    return a.to(b)
  }

  /*!
  * Gather data from from.
  */
  getFormData(form, options={forceNullOnEmpty: false}){
    let inputElements = form.getElementsByClassName('input')
    let obj = {}
    for(let i=0;i<inputElements.length;i+=1){
      let inputElement = inputElements[i]
      if (!inputElement.name || !inputElement.value) continue
      obj[inputElement.name] = inputElement.value
    }
    return obj
  }

  /*!
   * Convert a number to string with commas to separate by the 1000 (for money) and a $ prefix
   * @param  {number}   form The form to serialize
   * @return {String}     The comma-separated string 
   */
  getMoneyNumber(x, prefix='$', suffix=' USD') {
    return `${prefix}${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${suffix}`
  }

  /*!
   * Make a DOM element animate, depends on animate.css
   * @param  {node}   element The DOM element to animate.
   */
  animate(element, animation = 'pulse'){
    if (element.className.indexOf('animated') !== -1) {
      let arr = element.className.split(' ')
      let index = arr.indexOf('animated')
      arr.splice(index,2)
      element.className = arr.join(' ')
    }
    const anim = ` animated ${animation}`
    element.className += anim
    setTimeout(()=>{
      element.className = element.className.split(anim)[0]
    },500)
  }

  getSearchObj(str){
    let s = str.substr(1,str.length-1)
    let arr = s.split('&')
    let obj = {}

    for (let i=0;i<arr.length;i+=1){
      let split = arr[i].split('=')
      obj[split[0]] = split[1]
    }

    return obj
  }

  isEmptyObj(obj){
    return (
      obj
      && Object.keys(obj).length === 0
      && obj.constructor === Object
    )
  }

  differenceInMinutes(date1, date2) {
    const diffInMilliSeconds = Math.abs(new Date(date1) - new Date(date2));
    return Math.floor(diffInMilliSeconds / 1000 / 60)
  }

  getAspectRatio(width, height) {
    const ratio = width / height
    const standardRatios = {
      'is-3by1': 3/1,
      'is-2by1': 2/1,
      'is-16by9': 16/9,
      'is-5by3': 5/3,
      'is-3by2': 3/2,
      'is-4by3': 4/3,
      'is-5by4': 5/4,
      'is-1by1': 1/1,
      'is-4by5': 4/5,
      'is-3by4': 3/4,
      'is-2by3': 2/3,
      'is-3by5': 3/5,
      'is-9by16': 9/16,
      'is-1by2': 1/2,
      'is-1by3': 1/3,
    }

    let smallest = 4;
    let winner = null
    for (const aspect in standardRatios) {
      const decimal = standardRatios[aspect]
      const difference = Math.abs(decimal - ratio)
      if (difference < smallest) {
        smallest = difference
        winner = aspect
      }
    }
    return winner
  }

  static getGoogleCode() {
    const rootUrl = 'https://accounts.google.com/o/oauth2/v2/auth'

    const options = {
      redirect_uri: process.env.REACT_APP_PUBLIC_GOOGLE_OAUTH_REDIRECT,
      client_id: process.env.REACT_APP_PUBLIC_GOOGLE_CLIENT_ID,
      access_type: 'offline',
      response_type: 'code',
      prompt: 'consent',
      scope: [
        "https://www.googleapis.com/auth/userinfo.profile",
			  "https://www.googleapis.com/auth/userinfo.email",
		  ].join(" ")
    }
    
    const qs = new URLSearchParams(options)

    return `${rootUrl}?${qs.toString()}`
  }
}


