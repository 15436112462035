import React, { useContext, useRef, useEffect } from 'react'
import { AuthContext } from '../../contexts/auth'
import { ModalContext } from '../../contexts/modal'
import Utils from '../../utils'
import './index.scss'

const Login = () => {
  const { auth, authMethods } = useContext(AuthContext)
  const { isAuthenticated, me } = auth
  const { login, getProfile } = authMethods 

  const onSubmit = (username, password) => {
    if (username && password) login(username, password)
  }

  useEffect(()=>{
    if (isAuthenticated && !me) getProfile()
  })

  return isAuthenticated ? (
    <AccessView />
  ) : (
    <LoginForm onSubmit={onSubmit} />
  )
}

export default Login

const AccessView = () => {
  const { display_name } = useContext(AuthContext).auth.me
  const { close } = useContext(ModalContext).modalMethods
  setTimeout(()=>close(),500)
  return (
    <div>
      Logged in as <strong>{ display_name }</strong>.
    </div>
  )
}

const LoginForm = ({ onSubmit }) => {
  const username = useRef()
  const password = useRef()
  return (
    <div className="container">
      <form id="login" onSubmit={(e)=>{
        e.preventDefault()
        onSubmit(username.current.value, password.current.value)
      }}>
        <div className="columns">
          <div className="column has-text-centered">
            <img
              alt="Funky Outer Space"
              src="/img/fos_logo.svg"
              className="m-t-md m-b-md logo"
            />
          </div>
        </div>
        <div className="columns">
          <div className="column p-b-sm">
            <input
              className="input"
              type="text"
              name="username"
              ref={username}
              placeholder="Email"
            />
          </div>
        </div>
        <div className="columns">
          <div className="column p-t-none p-b-sm">
            <input
              className="input"
              type="password"
              name="password"
              ref={password}
              placeholder="Password"
            />
          </div>
        </div>
        <div className="columns">
          <div className="column p-t-none has-text-right">
            <button
              className="button is-primary"
              type="submit"
            >Submit</button>
          </div>
        </div>
      </form>
      <div className="columns">
          <div className="column p-t-10 has-text-right">
            <a href={Utils.getGoogleCode()}>
              <button
                className="button is-secondary"
                type="button"
              >Google</button>
            </a>
          </div>
        </div>
    </div>
  )
}
