import React from 'react'

const DEFAULT_MSG = 'Are you sure?'
const ConfirmationModal = props => {
  const {
    msg = DEFAULT_MSG,
    submit = ()=>{},
    cancel = ()=>{}
  } = props
  return (
    <div className="confirmation-modal">
      <p className="is-size-4 m-b-sm">
        { msg }
      </p>
      <div className="columns is-desktop is-mobile">
        <div className="column is-half has-text-right">
          <button
            className="button is-secondary"
            type="button"
            onClick={()=>{
              cancel()
            }}
          >No</button>
        </div>
        <div className="column is-half">
          <button
            className="button is-primary"
            type="button"
            onClick={()=>{
              submit()
            }}
          >Yes</button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal
