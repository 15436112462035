import React, { useContext, useEffect, useState } from 'react'
import { MediaContext } from '../../contexts/media'
import Service from '../../service'
import Utils from '../../utils'
import Paginator from '../../components/paginator'

const { getAspectRatio, copyStringToClipboard } = new Utils()
const RESULTS_PER_PAGE = 6

export default function ImportImages () {
  const {
    mediaMethods: {
      getMedia,
      setMediaImported,
    },
    media,
    mediaCollections,
    activeMediaCollection,
    mediaImported,
  } = useContext(MediaContext)

  const [ page, setPage ] = useState(1)

  useEffect(()=>{
    getMedia()
  },[])

  const filteredMedia = media.filter(image =>
    !mediaImported?.includes(image.filePath)
  )

  return (
    <div className="box">
      <h3 className="m-b-md has-text-primary">Import images</h3>
      <div className="columns is-multiline is-variable">
        {filteredMedia.length > 0 ? filteredMedia.map((image, i)=>{
          if ((i+1) > (page * RESULTS_PER_PAGE) || (i+1) < ((page-1) * RESULTS_PER_PAGE)) return null
          return (
            <div className="column is-4-tablet is-3-desktop is-2-fullhd has-text-centered has-text-info" key={`image-${i}`}>
              <div className={`image ${getAspectRatio(image.width, image.height)}`}>
                <img
                  src={`${Service.CDN}${image.filePath}?w=300`}
                  className="pointer"
                  onClick={()=>{
                    setMediaImported([
                      ...mediaImported,
                      image.filePath,
                    ])
                  }}
                />
              </div>
            </div>
          )
        }) : (
          <h3>No media to show.</h3>
        )}
      </div>
      {media.length > RESULTS_PER_PAGE ? (
        <div className="columns">
          <div className="column has-text-right">
            <Paginator
              count={media.length}
              limit={RESULTS_PER_PAGE}
              page={page}
              action={setPage}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}
