import React, { useContext } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { AuthContext } from '../../contexts/auth'
import { SidebarContext } from '../../contexts/sidebar'
import KEY from './key'

const Sidebar = () => {
  const { auth, authMethods } = useContext(AuthContext)
  const { isFloating, isOpen } = useContext(SidebarContext).sidebar
  const { isAuthenticated } = auth
  const { logout } = authMethods
  const blur = isAuthenticated ? '0px' : '5px'
  const { pathname } = useLocation()
  const history = useHistory()

  const getIsActive = path => ((path === '/' && path === pathname) || (path !== '/' && pathname.includes(path))) ? 'is-active' : ''

  const getIsFloating = ()=>(isFloating)?'is-floating':''

  const getIsHidden = ()=>(!isOpen && isFloating)?'is-hidden':''
  return (
    <aside
      className={`menu has-background-info p-t-xl ${getIsFloating()} ${getIsHidden()}`}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '250px',
        fontWeight: 'bold',
        filter: `blur(${blur})`,
        zIndex: 4,
      }}
    >
      {
        KEY.map((item, i)=>(
          <React.Fragment key={`category-${i}`}>
            <p className="menu-label p-l-md has-text-primary">
              { item.category }
            </p>
            <ul className="menu-list">
              {
                item.menu.map((menuItem, i)=>{
                  const isSignOut = menuItem.display === 'Sign Out'
                  const isActive = isSignOut ? false : getIsActive(menuItem.href)
                  return (
                    <li key={`menu-item-${i}`}>
                      <Link
                        to={menuItem.href}
                        className={[
                          'p-l-lg',
                          'p-r-lg',
                          'transition-all',
                          isActive,
                        ].join(' ')}
                        onClick={isSignOut?(e)=>{
                          e.preventDefault()
                          logout()
                          history.push('/')
                        }:()=>{}}
                      >{menuItem.display}</Link>
                    </li>
                  )
                })
              }
            </ul>
          </React.Fragment>
        ))
      }
    </aside>
  )
}

export default Sidebar
