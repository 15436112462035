import React, { useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { UsersContext } from '../../contexts/users'
import Breadcrumbs from '../../components/breadcrumbs'

const UsersCreate = () => {
  const { usersMethods } = useContext(UsersContext)
  const history = useHistory()
  const refs = {
    display_name: useRef(),
    email: useRef(),
  }
  const createUser = () => {
    const user = {
      display_name: refs.display_name.current.value,
      email: refs.email.current.value,
    }
    usersMethods.createUser(user).then(()=>{
      history.push('/users')
    })
  }
  return (
    <div className="section">
      <Breadcrumbs />
      <div className="box">
        <form className="form">
          <div className="field">
            <label className="label" htmlFor="display_name">Display Name</label>
            <div className="control">
              <input
                type="text"
                className="input"
                id="display_name"
                ref={refs.display_name}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="email">Email</label>
            <div className="control">
              <input
                type="text"
                className="input"
                id="email"
                ref={refs.email}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button
                type="button"
                className="button is-primary"
                onClick={createUser}
              >Create</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default UsersCreate
