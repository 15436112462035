import React, { useContext, useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { UsersContext } from '../../contexts/users'
import { ModalContext } from '../../contexts/modal'
import ConfirmationModal from '../../components/confirmation-modal'
import Breadcrumbs from '../../components/breadcrumbs'
import Spinner from '../../components/spinner'
import NewButton from '../../components/new-button'
import './index.scss'

const UsersList = () => {
  const {
    users,
    usersMethods,
    usersIsFetching
  } = useContext(UsersContext)

  const { open, close, setContent } = useContext(ModalContext).modalMethods

  const {
    getAllUsers,
    requestPasswordReset,
    requestActivation,
  } = usersMethods

  useEffect(() => {
    getAllUsers()
  },[])

  const s = users.allUsers.length === 1 ? '' : 's'
  const [ selectedUsers, setSelectedUsers ] = useState([])
  const actionRef = useRef()

  const batchAction = action => {
    selectedUsers.forEach(id => {
      const u = users.allUsers.find(user=>user.id===id)
      switch(action){
        case 'activation':
          requestActivation(u.email)
        break;
        case 'password-reset':
          requestPasswordReset(u.email)
        break;
        default:
        break;
      }
    })
  }

  const inputChange = e => {
    const { id } = e.target
    const userId = id.substr(5) 
    let updatedUsers
    if (selectedUsers.includes(userId)) {
      const index = selectedUsers.indexOf(userId)
      updatedUsers = [
        ...selectedUsers.slice(0,index),
        ...selectedUsers.slice(index+1)
      ]
    } else {
      updatedUsers = [ userId, ...selectedUsers ]
    }
    setSelectedUsers(updatedUsers)
  }

  const formatDate = date => moment(date).format('MMM D YYYY HH:mm')

  return (
    <div className="section">
      {usersIsFetching.allUsers ? (
        <div className="columns is-vcentered is-fullheight">
          <div className="column has-text-centered">
            <Spinner size="3x" />
          </div>
        </div>
      ):(
        <>
          <Breadcrumbs />
          <Link
            to="users/create"
            className="is-pulled-right p-none"
          >
            <NewButton />
          </Link>
          <div className="select">
            <select className="input is-inline m-r-sm m-b-md" ref={actionRef}>
              <option value="none">---   Select Action   ---</option>
              <option value="password-reset">Send password reset</option>
              <option value="activation">Re-send activation</option>
            </select>
          </div>
          <button
            type="button"
            className="button is-primary is-inline"
            onClick={()=>{
              if (!selectedUsers.length) return
              const action = actionRef.current.value
              setContent(()=>(
                <ConfirmationModal
                  submit={()=>{
                    batchAction(action)
                    close()
                  }}
                  cancel={()=>{
                    close()
                  }}
                />
              ))
              open('300px')
            }}
            disabled={(selectedUsers.length < 1 || actionRef?.current?.value === 'none')}
          >Submit</button>
          <div className="box animated fadeIn users-list-box is-paddingless m-t-lg">
            <table className="table is-bordered is-striped is-fullwidth">
              <thead>
                <tr>
                  <th style={{width: '38px'}}></th>
                  <th>
                    Name
                  </th>
                  <th>
                    Email
                  </th>
                  <th className="is-hidden-mobile">
                    Created
                  </th>
                  <th className="is-hidden-mobile">
                    Activated
                  </th>
                 <th className="is-hidden-mobile">
                    Last sign-in 
                  </th>

                </tr>
              </thead>
              <tbody>
                { users.allUsers.map(user => (
                  <tr key={`user-${user.id}`}>
                    <td><input type="checkbox" onChange={inputChange} id={`user-${user.id}`} /></td>
                    <td>
                      {user.display_name}
                    </td>
                    <td>
                      {user.email}
                    </td>
                    <td className="is-hidden-mobile">
                      {formatDate(user.created_at)}
                    </td>
                    <td className="is-hidden-mobile">
                      {formatDate(user.activated_at)}
                    </td>
                    <td className="is-hidden-mobile">
                      {formatDate(user.last_active)}
                    </td>

                  </tr>
                )) }
              </tbody>
            </table>
          </div>
          <p className="m-t-md is-fullwidth has-text-right">Showing { users.allUsers.length } user{s}</p>
        </>
      )}
    </div>
  )  
}

export default UsersList
