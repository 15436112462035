import React, { createContext, useState } from 'react'

export const ModalContext = createContext()

const ModalProvider = props => {
  const [ isActive, setIsActive ] = useState(false)
  const [ content, setContent ] = useState(null)
  const [ width, setWidth ] = useState(null)

  const modal = {
    isActive,
    content,
    width,
  }

  const modalMethods = {
    open: () => {
      setIsActive(true)
    },
    close: () => {
      setIsActive(false)
    },
    setContent,
    setModalWidth: newWidth => {
      setWidth(newWidth)
    }
  }

  return (
    <ModalContext.Provider
      value={{ modal, modalMethods }}
    >
      {props.children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
