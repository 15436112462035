import React, { useContext, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import { AnalyticsContext } from '../../contexts/analytics'
import Utils from '../../utils'

const lineChartOptions = {
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true,
      },
    }],
  },
}

const REFRESH_THRESHOLD = 5 //minutes before refreshing analytics data
const utils = new Utils()

const Insights = ({time, prevTime, tab, prevTab, site, prevSite}) => {
  const {
    analytics,
    analyticsMethods,
  } = useContext(AnalyticsContext)

  const { getInsights } = analyticsMethods

  const {
    locations,
    technologies,
    devices,
    pages,
  } = analytics.insights.reports

  useEffect(()=>{
    if ( 
      !analytics.insights.timestamp
      || utils.differenceInMinutes(
        analytics.insights.timestamp,
        (new Date().toISOString())
      ) >= REFRESH_THRESHOLD
      || time !== prevTime
      || tab !== prevTab
      || site !== prevSite
    ) {
      getInsights(time, site)
    }
  },[analytics.insights.timestamp, time, site])

  return (
    <div className="columns is-desktop is-mobile is-multiline">
      <div className="column is-full-tablet is-half-desktop">
        <div className="box">
          <Bar data={devices} options={lineChartOptions} />
        </div>
      </div>
      <div className="column is-full-tablet is-half-desktop">
        <div className="box">
          <Bar data={technologies} options={lineChartOptions} />
        </div>
      </div>
      <div className="column is-full-tablet is-half-desktop">
        <div className="box">
          <Bar data={pages} options={lineChartOptions} />
        </div>
      </div>
      <div className="column is-full-tablet is-half-desktop">
        <div className="box">
          <Bar data={locations} options={lineChartOptions} />
        </div>
      </div>
    </div>
  )
}

export default Insights
