import React, { createContext, useState } from 'react'
import Service from '../service'

export const UsersContext = createContext()

const DEFAULT = {
  allUsers: [],
  lastCreatedUser: {
    display_name: '',
    email: '',
  }
}

const UsersProvider = props => {
  const [ allUsers, setAllUsers ] = useState(DEFAULT.allUsers)
  const [ allUsersIsFetching, setAllUsersIsFetching ] = useState(false)
  const [ createUserIsFetching, setCreateUserIsFetching ] = useState(false)
  const [ requestPasswordResetIsFetching, setRequestPasswordResetIsFetching ] = useState(false)

  const usersMethods = {
    getAllUsers: async () => {
      setAllUsersIsFetching(true)
      const { data } = await Service.getAllUsers()
      setAllUsers(data.users)
      setAllUsersIsFetching(false)
    },
    createUser: async (user) => {
      setCreateUserIsFetching(true)
      const res = await Service.createUser(user)
      if (res.ok) {
        const data = await res.json()
        setAllUsers(data.users)
      }
      setCreateUserIsFetching(false)
    },
    requestPasswordReset: async (email) => {
      setRequestPasswordResetIsFetching(true)
      const res = await Service.requestPasswordReset(email)
      setRequestPasswordResetIsFetching(false)
      return res
    },
    requestActivation: async (id) => {
      await Service.requestActivation(id)
    },
  }

  const users = {
    allUsers,
  }

  const usersIsFetching = {
    allUsers: allUsersIsFetching,
    creatingUser: createUserIsFetching,
    requestPasswordReset: requestPasswordResetIsFetching,
  }

  return (
    <UsersContext.Provider
      value={{users, usersMethods, usersIsFetching}}
    >
      {props.children}
    </UsersContext.Provider>
  )
}

export default UsersProvider
