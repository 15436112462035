export default [{
  category: 'General',
  menu: [{
    display: 'Home',
    href: '/',
  },{
    display: 'Media Library',
    href: '/media',
  },{
    display: 'Blog Posts',
    href: '/blog',
  },{
    display: 'SEO',
    href: '/seo',
  },{
    display: 'Users',
    href: '/users',
  }]
},{
  category: 'Account',
  menu: [{
    display: 'Profile',
    href: '/profile',
  },{
    display: 'Sign Out',
    href: '/',
  }]
}]
