import React, {
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react'
import Service from '../service'
import { ModalContext } from './modal'
import Login from '../components/login'
import { useHistory } from "react-router-dom";

export const AuthContext = createContext()

const token = Service.getAccessToken()

const DEFAULT = {
  isAuthenticated: false,
  hasToken: !!token,
  me: {
    id: null,
    display_name: null,
    email: null,
  },
}

const AuthProvider = props => {
  const [ isAuthenticated, setIsAuthenticated ] = useState(DEFAULT.isAuthenticated)
  const [ hasToken, setHasToken ] = useState(DEFAULT.hasToken)
  const [ me, setMe ] = useState(DEFAULT.me)
  const [ loginIsFetching, setLoginIsFetching ] = useState(false)
  const [ profileIsFetching, setProfileIsFetching ] = useState(false)
  const [ ssoIsFetching, setSsoIsFetching ] = useState(false)

  const { modalMethods } = useContext(ModalContext)
  const { open, setContent } = modalMethods
  const history = useHistory()

  const authStatus = {
    loginIsFetching,
    profileIsFetching,
    ssoIsFetching,
  }

  const loginSuccessful = (data) => {
    const { token, user } = data
    Service.setAccessToken(token)
    setHasToken(true)
    setIsAuthenticated(true)
    setMe(user)
    setLoginIsFetching(false)
  }

  const authMethods = {
    login: async (email, password) => {
      setLoginIsFetching(true)
      const res = await Service.login(email, password)
      const { data } = await res.json()
      if (res.ok) {
        loginSuccessful(data)
      }
    },
    loginGoogle: async (code) => {
      setSsoIsFetching(true)
      const res = await Service.loginGoogle(code)
      const { data } = await res.json()

      if (res.ok) {
        loginSuccessful(data)
      } 
      history.push('/')
    },
    logout: async () => {
      setIsAuthenticated(false)
      setHasToken(false)
      setMe(DEFAULT.me)
      Service.logout()
    },
    getProfile: async () => {
      setProfileIsFetching(true)
      const res = await Service.getProfile()
      if (res.ok) {
        const { data } = await res.json()
        setMe(data.user)
        setIsAuthenticated(true)
      }
      setProfileIsFetching(false)
    },
  }

  useEffect(()=>{
    if (!isAuthenticated){
      if (!hasToken){
        setContent(
          <Login />
        )
        open('400px')
      } else if(!me.id) {
        authMethods.getProfile()
      }
    } else if (me.id && !me.a) {
      authMethods.logout()
      window.location.reload()
    }
  }, [isAuthenticated, hasToken, me.id, me.a])

  const auth = {
    isAuthenticated,
    hasToken,
    me,
  }

  return (
    <AuthContext.Provider
      value={{auth, authMethods, authStatus}}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
