import React, { useContext, useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import { AnalyticsContext } from '../../contexts/analytics'
import Utils from '../../utils'

const lineChartOptions = {
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true,
      },
    }],
  },
}

const REFRESH_THRESHOLD = 5 //minutes before refreshing analytics data
const utils = new Utils()

const Engagement = ({time, prevTime, tab, prevTab, site, prevSite}) => {
  const {
    analytics,
    analyticsMethods,
  } = useContext(AnalyticsContext)

  const { getEngagement } = analyticsMethods

  const {
    sessions,
    interactivity,
    duration,
  } = analytics.engagement.reports

  useEffect(()=>{
    if ( 
      !analytics.engagement.timestamp
      || utils.differenceInMinutes(
        analytics.engagement.timestamp,
        (new Date().toISOString())
      ) >= REFRESH_THRESHOLD
      || time !== prevTime
      || tab !== prevTab
      || site !== prevSite
    ) getEngagement(time, site)
  },[analytics.engagement.timestamp, time, site])

  return (
    <div className="columns is-desktop is-mobile is-multiline">
      <div className="column is-full-tablet is-half-desktop">
        <div className="box">
          <Line data={sessions} options={lineChartOptions} />
        </div>
      </div>
      <div className="column is-full-tablet is-half-desktop">
        <div className="box">
          <Line data={interactivity} options={lineChartOptions} />
        </div>
      </div>
      <div className="column is-full-tablet is-half-desktop is-offset-one-quarter-desktop">
        <div className="box">
          <Line data={duration} options={lineChartOptions} />
        </div>
      </div>
    </div>
  )
}

export default Engagement
