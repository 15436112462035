import Cookie from 'browser-cookies'

//Vars to export
const API = `${process.env.REACT_APP_API}/v1`
const COOKIE_HOST = process.env.REACT_APP_COOKIE_HOST
const CDN = process.env.REACT_APP_CDN

//Methods to export
const methods = {
  setAccessToken: (at) => {
    Auth.accessToken = at
    _setCookie('at', at, COOKIE_HOST)
    _setCookie('bgna', 'bgna', COOKIE_HOST)
  },
  getAccessToken(){
    const cookie = _getCookie('at', COOKIE_HOST)
    if (cookie && !Auth.accessToken) {
      Auth.accessToken = cookie
    }
    return cookie
  },
  getProfile: async () => {
    const uri = _getFullPath(uri_profile)
    const res = await _superFetch(uri)
    return res
  },
  getAllUsers: async () => {
    const uri = _getFullPath(uri_users)
    const res = await _superFetch(uri)
    return await res.json()
  },
  createUser: async (body) => {
    const uri = _getFullPath(uri_users)
    const res = await _superFetch(uri, 'POST', body)
    return res
  },
  getBlogPosts: async () => {
    const uri = _getFullPath(uri_blogs)
    const res = await _superFetch(uri)
    return res
  },
  createBlogPost: async (body) => {
    const uri = _getFullPath(uri_blogs)
    const res = await _superFetch(uri,'POST',body)
    return res
  },
  updateBlogPost: async (id, body) => {
    const uri = _getFullPath(uri_blog).replace(/:id/, id)
    const res = await _superFetch(uri,'PUT',body)
    return res
  },
  deleteBlogPost: async (id) => {
    const uri = _getFullPath(uri_blog).replace(/:id/, id)
    const res = await _superFetch(uri,'DELETE')
    return res
  },
  publishSeo: async () => {
    const uri = _getFullPath(uri_seo_publish)
    const res = await _superFetch(uri)
    return res
  },
  getSeoRoutes: async () => {
    const uri = _getFullPath(uri_seo_routes)
    const res = await _superFetch(uri)
    return res
  },
  createSeoRoute: async (body) => {
    const uri = _getFullPath(uri_seo)
    const res = await _superFetch(uri, 'POST', body)
    return res
  },
  updateSeoRoute: async (body) => {
    const uri = _getFullPath(uri_seo)
    const res = await _superFetch(uri, 'PUT', body)
    return res
  },
  deleteSeoRoute: async (id) => {
    const uri = _getFullPath(uri_seo)
    const res = await _superFetch(uri, 'DELETE', { id })
    return res
  },
  lockBlogPost: async (id) => {
    const uri = _getFullPath(uri_blog_lock)
      .replace(/:blogid/, id)
    const res = await _superFetch(uri)
    return res
  },
  unlockBlogPost: async (id) => {
    const uri = _getFullPath(uri_blog_unlock)
      .replace(/:blogid/, id)
    const res = await _superFetch(uri)
    return res
  },
  requestPasswordReset: async (email) => {
    const uri = _getFullPath(uri_requestPasswordReset)
    const res = await _superFetch(uri, 'POST', {
      email,
    })
    return res
  },
  requestActivation: async (id) => {
    const uri = _getFullPath(uri_requestActivation)
    const res = await _superFetch(uri, 'POST', {
      id,
    })
    return res
  },
  login: async (email, password) => {
    const creds = {
      email,
      password,
    }
    const uri = _getFullPath(uri_login)
    const res = await fetch(
      uri,
      {
        method: 'POST',
        body: JSON.stringify(creds),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return res
  },
  loginGoogle: async (code) => {
    const uri = _getFullPath(uri_ssoGoogle)
    const body = { code }
    const res = await fetch(
      uri,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return res
  },
  logout: () => {
    Auth.accessToken = null
    _eraseCookie('at', COOKIE_HOST)
  },
  getAnalytics: async (type, time, site) => {
    const uri = _getFullPath(uri_analytics)
      .replace(/:type/,type)
      .replace(/:time/,time)
      .replace(/:site/,site)
    const res = await _superFetch(uri)
    return res
  },
  getSockets: async () => {
    const uri = _getFullPath(uri_sockets)
    const res = await _superFetch(uri)
    return res
  },
  getMedia: async () => {
    const uri = uri_media
    const res = await _superFetch(uri)
    return res
  },
  uploadMedia: async (file, name, shouldLock) => {
    const formData = new FormData()
    formData.append('file', file, file.name)
    formData.append('shouldLock', shouldLock)
    const uri = uri_media_upload
    const res = await _superFetch(uri, 'POST', formData, true);
    if (res.ok) {
      const data = await res.json()
      return data
    }
    return 'Could not upload media'
  },
  deleteMedia: async (name) => {
    const uri = uri_media_delete.replace(/:name/, name)
    const res = await _superFetch(uri, 'DELETE')
    return res
  },
  removeMediaFromCollection: async (fileName, collectionId) => {
    const uri = uri_media_collection_remove_media
    const res = await _superFetch(uri, 'DELETE', {
      fileName,
      collectionId,
    })
    return res
  },
  lockMedia: async (name) => {
    const uri = uri_media_lock.replace(/:name/, name)
    const res = await _superFetch(uri)
    return res
  },
  unlockMedia: async (name) => {
    const uri = uri_media_unlock.replace(/:name/, name)
    const res = await _superFetch(uri)
    return res
  },
  getMediaDestinations: async () => {
    const res = await _superFetch(uri_media_destination)
    return res
  },
  getMediaCollections: async () => {
    const res = await _superFetch(uri_media_collection)
    return res
  },
  createMediaCollection: async (files, title, assigned_to) => {
    const res = await _superFetch(
      uri_media_collection,
      'POST',
      {
        files,
        title,
        assigned_to,
      },
    )
    return res
  },
  updateMediaCollection: async (id, files, title, assigned_to) => {
    const res = await _superFetch(
      uri_media_collection,
      'PUT',
      {
        id,
        files,
        title,
        assigned_to,
      },
    )
    return res
  },
  deleteMediaCollection: async (id) => {
    const res = await _superFetch(
      uri_media_collection,
      'DELETE',
      {
        id,
      },
    )
    return res
  },
}

//Export combined object
export default {
  ...methods,
  CDN,
}

//Local vars
const TOKEN_TYPE = 'Bearer'
let Auth = {
  accessToken: null,
  tokenType: TOKEN_TYPE,
}

const cookieDays = 2

//Local methods
const _setCookie = (name, data, domain=COOKIE_HOST) => {
  Cookie.set(name, data, {
    expires: cookieDays,
    domain,
  })
}

const _eraseCookie = (name, domain=COOKIE_HOST) => {
  Cookie.erase(name, { domain })
}

const _getCookie = (name, domain=COOKIE_HOST) => Cookie.get(name, { domain })

const _getAuthHeaders = (uri, isUpload=false) => {
  const { NODE_ENV } = process.env
  const resObj = {
    'Authorization': `Bearer ${Auth.accessToken}`,
  }

  if (!isUpload) resObj['Content-Type'] = 'application/json'
  return resObj
}

const _getFullPath = (uri) => {
  return `${API}${uri}`
}
const _superFetch = async (uri, method='GET', body={}, isUpload=false) => {
  const params = {
    headers: _getAuthHeaders(uri, isUpload),
    method,
  }
  if (typeof body === 'object' && Object.keys(body).length > 0 && !isUpload) {
    params.body = JSON.stringify(body)
  } else if (isUpload) {
    params.body = body
  }
  const res = await fetch(uri,params)
  switch (res.status){
  case 403:
  case 401:
    methods.logout()
    window.location.reload()
    break
  case 500:
    throw await res.json()
    break
  default:
    break
  }
  return res
}

//URI's
const uri_login = '/auth/login'
const uri_ssoGoogle = '/auth/sso-google'
const uri_profile = '/auth/profile'
const uri_requestPasswordReset = '/auth/request-reset'
const uri_requestActivation = '/users/request-activation'
const uri_blogs = '/blog'
const uri_blog = '/blog/:id'
const uri_blog_lock = '/blog/lock?id=:blogid'
const uri_blog_unlock = '/blog/unlock?id=:blogid'
const uri_users = '/users'
const uri_analytics = '/analytics/report?type=:type&time=:time&site=:site'
const uri_sockets = '/analytics/sockets'
const uri_media = `${CDN}api/cdn`
const uri_media_upload = `${CDN}api/cdn/upload`
const uri_media_collection = `${CDN}api/cdn/media-collection`
const uri_media_collection_remove_media = `${CDN}api/cdn/media-collection/remove-media`
const uri_media_destination = `${CDN}api/cdn/media-destination`
const uri_media_delete = `${CDN}api/cdn/:name`
const uri_media_lock = `${CDN}api/cdn/lock?name=:name`
const uri_media_unlock = `${CDN}api/cdn/unlock?name=:name`
const uri_seo_publish = '/seo/publish'
const uri_seo_routes = '/seo/routes'
const uri_seo = '/seo'
