import React, { useCallback } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import Service from '../../service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'


// fake data generator
const getItems = images =>
  images.map((image, i) => ({
    id: `orderable-image-${i}`,
    src: `${image}`,
  }))

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",
  // styles we need to apply on draggables
  ...draggableStyle
})

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
})

class OrderableImages extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: Array.isArray(props.images) ? getItems(props.images) : getItems(0)
    }
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  componentDidUpdate(prevProps){
    if (this.props.images.length !== prevProps.images.length) {
      this.setState({
        items: getItems(this.props.images)
      })
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    )

    this.setState({
      items
    })

    this.props?.onDragEnd(items)
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const columnSize = 'is-3'
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              className="columns is-multiline is-variable"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.state.items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className={`column ${columnSize}`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="is-pulled-right pointer"
                        onClick={()=>{
                          this.props.onRemove(item)
                        }}
                      />
                      <img src={`${Service.CDN}${item.src}`} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
}

export default OrderableImages
