import React, { createContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Service from '../service'
import Utils from '../utils'

export const MediaContext = createContext()
const { getSearchObj } = new Utils()

const MediaProvider = props => {
  const [ media, setMedia ] = useState([])
  const [ mediaCollections, setMediaCollections ] = useState([])
  const [ mediaDestinations, setMediaDestinations ] = useState([])
  const [ mediaIsFetching, setMediaIsFetching ] = useState(false)
  const [ mediaImported, setMediaImported ] = useState([])
  const [ activeMediaCollection, setActiveMediaCollection ] = useState(null)
  const { search, pathname } = useLocation()
  const { id } = getSearchObj(search)
  const mediaMethods = {
    getMedia: async () => {
      setMediaIsFetching(true)
      const res = await Service.getMedia()
      if (res.ok) {
        const data = await res.json()
        setMedia(data.files)
      }
      setMediaIsFetching(false)
    },
    uploadMedia: async (file, name) => {
      const isForCollection = pathname.includes('media-collection') && !!id
      setMediaIsFetching(true)
      const {
        data: {
          filename,
        }
      } = await Service.uploadMedia(file, name, isForCollection)
      if (isForCollection) {
        setMediaImported([
          ...mediaImported,
          filename,
        ])
      }
    },
    deleteMedia: async (id) => {
      await Service.deleteMedia(id)
    },
    lockMedia: async (fileName) => {
      await Service.lockMedia(fileName)
    },
    unlockMedia: async (fileName) => {
      await Service.unlockMedia(fileName)
    },
    getMediaDestinations: async () => {
      const res = await Service.getMediaDestinations();
      if (res.ok) {
        const { destinations } = await res.json()
        setMediaDestinations(destinations)
      }
    },
    getMediaCollections: async () => {
      const res = await Service.getMediaCollections();
      if (res.ok) {
        const { collections } = await res.json()
        setMediaCollections(collections.map(collection => ({
          ...collection,
          content: JSON.parse(collection.content),
        })))
      }
    },
    setMediaImported: (media) => {
      setMediaImported(media)
    },
    setActiveMediaCollection: (collection) => {
      setActiveMediaCollection(collection)
    },
    createMediaCollection: async (files, title, assigned_to) => {
      const res = await Service.createMediaCollection(files, title, assigned_to)
      if (res.ok) {
        const data = await res.json()
        const newMediaCollections = data.collections.map(collection => ({
          ...collection,
          content: JSON.parse(collection.content)
        }))
        setMediaCollections(newMediaCollections)
        setMediaImported([])
      }
    },
    updateMediaCollection: async (id, files, title, assigned_to) => {
      const res = await Service.updateMediaCollection(id, files, title, assigned_to)
      if (res.ok) {
        const data = await res.json()
        const newMediaCollections = data.collections.map(collection => ({
          ...collection,
          content: JSON.parse(collection.content)
        }))
        setMediaCollections(newMediaCollections)
        setMediaImported([])
      }
    },
    deleteMediaCollection: async (id) => {
      const res = await Service.deleteMediaCollection(id)
      if (res.ok) {
        const data = await res.json()
        const newMediaCollections = data.collections.map(collection => ({
          ...collection,
          content: JSON.parse(collection.content)
        }))
        setMediaCollections(newMediaCollections)
      }
    },
  }
  return (
    <MediaContext.Provider
      value={{ media, mediaDestinations, mediaCollections, mediaMethods, mediaIsFetching, mediaImported, activeMediaCollection }}
    >
      {props.children}
    </MediaContext.Provider>
  )
}

export default MediaProvider
